import React from "react";
import { useState } from "react";
import "../../assets/scss/Admin/trainees.scss";

interface Trainee {
  email: string;
  fullName: string;
  phone: string;
  course: string;
  couponCode: string;
  transactionDate: string;
}

const Trainees = () => {
  const [filter, setFilter] = useState("Paid");

  const trainees: Trainee[] = [];

  return (
    <div className="trainees-container">
      <header className="trainees-header">
        <h1>Trainees</h1>
      </header>
      <div className="trainees-wrapper">
        <div className="filter-section">
          <label htmlFor="filter">Filter by</label>
          <select
            id="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="Paid">Paid</option>
            <option value="Notpaid">Not paid</option>
            <option value="All-trainees">All Trainees</option>
            <option value="coupon-code">Coupon Code</option>
          </select>
        </div>

        <table className="trainees-table">
          <thead className="thread">
            <tr>
              <th>S.No</th>
              <th>Email</th>
              <th>Full Name</th>
              <th>Phone</th>
              <th>Course</th>
              <th>Coupon Code</th>
              <th>Transaction Date</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {trainees.length > 0 ? (
              trainees.map((trainee, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{trainee.email}</td>
                  <td>{trainee.fullName}</td>
                  <td>{trainee.phone}</td>
                  <td>{trainee.course}</td>
                  <td>{trainee.couponCode}</td>
                  <td>{trainee.transactionDate}</td>
                  <td>
                    <button className="view-btn">👁</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="no-data">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Trainees;
