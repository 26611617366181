import React from 'react';
import '../../assets/scss/blogs/effectiveLeader.scss';

import img1 from '../../assets/images/blogs/ships.png';
import img2 from '../../assets/images/blogs/mentorship.jpg';
import img3 from '../../assets/images/blogs/leadership.png';

import readBlog from "../../assets/images/blogs/readBlog.png";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

const EffectiveLeader = () => {
  return (
    <div className="effective-leader-content">
      {/* Banner Section */}
      <div className="banner-section">
        <div className="overlay">
          <h1>Striving to Serve: How to Become an Effective Leader</h1>
        </div>
      </div>

      
      <div className="image-section">
        <img className="ship-image" src={img1} alt="Ships" />
      </div>

      {/* Text Content Section */}
      <div className="text-section">
        <h2>Effective leadership is crucial for the success of any organisation, 
          but what does it mean to be an effective leader?</h2>

        <p>All teams hope to use good leaders as a measure of the ideal standard of 
          working, and as a compass to ensure that they are all aligned towards the 
          same goal. To maintain goodwill and trust within the team, a leader must 
          be a role model, whether that is by meeting deadlines, remaining punctual 
          or by completing work to a high standard. They don't have to be the most 
          skilled or knowledgeable membe of the team, but rather the most reliable 
          and consistent.</p>

        <p>Imagine a leader who doesn't dictate but acts as a guide, removing obstacles 
          and nurturing growth. This approach fuels a culture of autonomy where team 
          members flourish. The beauty lies in its simplicity - by putting the team's 
          needs first. A servant leader inspires shared ownership and relentless innovation. 
          It's not about commanding; it's about enabling. At the heart of this, lies a concept 
          that transcends traditional management approaches - servant leadership.</p>

        <h2 className='main-title'>Being a great leader can be measured by the positive 
          influence they have amongst their team. Effective leadership is a skill which
           all effective Scrum Masters should be striving to achieve. In what ways,
           however, can this be evidenced as a Scrum Master?</h2>
        
        {/* Mentor Image */}
        <div className="mentor-section">
        <div className="mentor-text">
            <p>Scrum Masters are true leaders who serve the Scrum team and the larger organisation.</p>
            <p>Although a leader, they are not authoritative, but a change agent that 
              is flexible and responsive to unexpected developments. Through teaching and 
              mentorship, a Scrum Master can inspire change and growth - not necessarily 
              providing the answers, but coaching and challenging the team to collaboratively 
              decide on the best solution. This can leave a lasting impact on how development 
              teams approach complex work in the future.</p>
        </div>

        <div className="image-mentor-section">
            <img className="mentor-image" src={img2} alt="Mentorship" />
        </div>
        </div>
          

        <p className='strong'>
        <strong>A Scrum Master</strong> is responsible for creating a thriving environment
        where a team is empowered to work cross-functionally. As opposed to “line managing,” 
        a Scrum master trusts the team to trust themselves and encourage the practice of 
        leadership amongst themselves, thus promoting continual growth.
        </p>

        <p className='strong'>
          <strong>A good leader</strong> will naturally build up a deep knowledge of the 
          different temperaments and competencies within a team - they need to be aware 
          of this in order to delegate tasks efficiently. In these situations, it’s important 
          for a leader to be able to differentiate between leading and ruling. While it 
          might be tempting to assign to someone a task that seems like their perfect fit, 
          decisions should be reached through mutual collaboration. Using only your own 
          judgement will never build the trust and respect that stems from the discussion 
          to reach a common middle ground, and will only end up hurting productivity overall.
        </p>

        {/* Leadership Image Section */}
        <div className="left-image-section">
          <img className="leader-image" src={img3} alt="Leadership" />
          <div className="right-text">
            <p>A true leader should be equally concerned about the growth of both the team and
               the product. One can achieve this through specific and frequent feedback - 
               and so it falls on the leader to foster a safe environment where constructive 
               criticism is commonplace.</p>
          </div>
        </div>

        <p>While the traits of leadership may initially seem straightforward; 
          they can be difficult to master and the servant leadership stance is no 
          exception. Practising servant leadership unlocks intrinsic motivation, fueling 
          a self-sustaining engine of productivity and morale. By fostering trust, 
          open communication, and a sense of purpose, servant leadership propels 
          teams to exceed expectations and achieve excellence. In the world of Scrum, 
          it's not just leadership - it's a transformative journey towards unparalleled 
          success.</p>
        {/* Blog Footer */}
        <div className="blog-foot">
          <div className="post">
            <img src={readBlog} alt="readblog" />
            <a href=""> &lt; Previous post</a>
          </div>
          <div className="rate-post">
            <p>
              Rate this Article <br />
              <strong>0 from 0 ratings</strong>
            </p>
            <p>Share with your network</p>
            <div className="sociallinks">
              <a href="https://www.facebook.com/lokem/" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </a>
              <a href="https://twitter.com/lokemuk" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href="https://www.linkedin.com/lokemuk?_l=en_US" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div className="post">
            <img src={readBlog} alt="readtheblog" />
            <a href="">Next post &gt;</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EffectiveLeader;
