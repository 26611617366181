import { createLogic } from "redux-logic";
import { ApiHelper } from "../helper/ApiHelper";
import { ApiRoutes, AppRoutes } from "../config";
import axios from "axios";

const fetchUserLogic1 = createLogic({
  type: "FETCH_USER_REQUEST", // action type to listen for
  latest: true, // take the latest action and ignore the previous

  process({ action }, dispatch, done) {
    axios
      .get("https://jsonplaceholder.typicode.com/comments") // Replace with your API endpoint
      .then((response) => {
        const data = response.data;
        dispatch({
          type: "FETCH_DATA_SUCCESS",
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_DATA_FAILURE",
          payload: error,
        });
      })
      .finally(done);
  },
});

const fetchUserLogic = createLogic({
  type: "FETCH_USER_REQUEST",
  async process(data, dispatch: any, done) {
    const action: any = data.action;
    // dispatch(showLoader());
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.HELP_FAQ_DATA.service,
      ApiRoutes.HELP_FAQ_DATA.url,
      ApiRoutes.HELP_FAQ_DATA.method,
      ApiRoutes.HELP_FAQ_DATA.authenticate,
      undefined,
      undefined
    );
    if (response && !response.isError) {
      const { data } = response.data;
      dispatch({
        type: "FETCH_DATA_SUCCESS",
        helpfaqData: data,
      });
      done();
    } else {
      dispatch({
        type: "FETCH_DATA_SUCCESS",
        helpfaqData: data,
      });
      done();
    }
  },
});

export default [fetchUserLogic];