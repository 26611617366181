import React from "react";
import "../../assets/scss/Admin/trainingdates.scss";

const TrainingDates = () => {
  const tableData = [
    {
      courseName: "Scrum Master Training",
      trainingDates: "sdfsf",
      postDate: "2022-06-01 12:02:48",
    },
    {
      courseName: "Business Analyst Training",
      trainingDates: "24th & 25th September 2022",
      postDate: "2022-06-01 12:02:48",
    },
    {
      courseName: "Product Owner Training",
      trainingDates: "31-1-2024",
      postDate: "2022-06-01 12:02:48",
    },
    {
      courseName: "Project Management Training",
      trainingDates: "51th & 16th Oct 2024 | 12nd & 43rd Nov 2024",
      postDate: "2022-12-05 11:01:13",
    },
  ];

  return (
    <div>
      <div className="training-dates-container">
        <h1>Show training dates</h1>
        <table className="training-table">
          <thead>
            <tr>
              <th>courseName</th>
              <th>TrainingDates</th>
              <th>PostDate</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.courseName}</td>
                <td>{row.trainingDates}</td>
                <td>{row.postDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrainingDates;
