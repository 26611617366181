import React from 'react';
import '../../assets/scss/blogs/simiJourney.scss';

import img1 from '../../assets/images/blogs/blogs1.png'

const SimiJourney = () => {
  return (
    <div className="blog-content">
      <h1 className="main-title">Simi's Journey: Project Assistant to Agile Delivery Manager</h1>

      <div className="highlighted-text">
        <div className="gold-line"></div>
        <p>“Agile training at Lokem will be the best decision you 
          will ever make. If you are thinking about changing your career 
          and getting into tech then this is the place to do it.”</p>
      </div>

      <p>Simi, a 28 year from East London, successfully transitioned from a 
        career in the Research Sector into Tech!</p>

      <p>When Simi started his journey at Lokem, he jumped right in and learned 
        all about Agile, managing teams and projects. Now a successful Agile 
        Delivery Manager at DNATA, Simi tells us about his training journey with Lokem.</p>

      <img className='simi' src={img1} alt="Simi's Journey" />

      <h2 className="blog-title">What is your title?</h2>
      <p>Agile Delivery Manager</p>

      <h2 className="blog-title">What were you doing before you began your 
        journey at Lokem and what was your motivation to consider the training?</h2>

      <p>I was working in the Clinical Research sector. I was a Project 
        assistant/Project Administrator at the time and I wanted to progress 
        but progression within that industry was really slow and I also felt 
        there was bias in that industry in regards to age. The older you are, 
        the more inexperienced you might look, which I didn't like, so I needed an 
        alternative that gave me higher prospects and higher pay.</p>

      <h2 className="blog-title">What was your experience like at Lokem?</h2>

      <p>Lokem was fantastic. I was lucky enough to even do the in-person workshop
         in January 2020 just before lockdown, so I got to meet my cohort as 
         well as Kemi and Lola.</p>

      <p>The workshop convinced me 100% that this was the path that I wanted to 
        take in my life and it was also the best financial decision I have ever 
        made in my 28 years of life.</p>
        
      <p>It was a crazy journey doing our Scrum ceremonies as well as attending 
        training sessions in the evenings/weekends whilst still juggling my day 
        job. It was hard but I was determined.</p>

      <p>I think lockdown made it so much easier to progress as I was working 
        from home. I spoke regularly with my cohorts to keep us motivated and 
        accountable . The trainings were very interactive and I learned so much 
        and everything you learn with Lokem is transferable into the actual real 
        life Agile environment</p>

      <h2 className="blog-title">How did you find the hands-on work experience 
        element of the training programme?</h2>

      <p>The work experience part was for me the most crucial part of the process. 
        Without it I don't think I would have been confident or even comfortable 
        enough to take on my roles that I did so far in my Agile career.</p>
      <p>Everything I did in the work experience element transferred itself to 
        the real world. For me it was the most crucial part of this process because 
        it gives you the actual genuine experience to transform Agile teams into 
        cross functional as well as a self organising team that knows how to solve its problems.</p>

      <h2 className="blog-title">What was the support like on the platform and throughout your training?</h2>

      <p>Support was 10/10. Any help I needed I knew the experts I could reach out to.</p>
      <p>I had a mentor when it came to interview prep and CV writing etc, so I was 
        grateful for the support I got. All the things I learned from my mentor at 
        Lokem are still in ingrained in my head and I make sure i pass on that 
        knowledge to people that need it.</p>

      <h2 className="blog-title">What has happened since completing the training?</h2>

      <p>Since my training I secured my first Scrum Master role at a company called 
        ACUE that had a development partnership/ contract with BT so I worked with 
        BT Agile teams in building key software deliverables over several release 
        trains at BT.</p>
        
      <p>A great opportunity came up to work as a Certified SAFe Release Train 
        Engineer for a SaaS software company called FOURTH, where I was coaching 
        and identifying key risks and gaps in Agility of 10-12 Agile teams as well 
        as collaborating with Agile and Tech leaders to facilitate the adherence 
        to the Scaled Agile Framework. Now I’m at my current company called DNATA 
        Travel Group where I am an Agile Delivery Manager overseeing Agile 
        Delivery of key ticketing and flights software for key players in the 
        Airline industry like British Airways, KLM, and Emirates Airline Group.</p>

      <p>Qualifications wise I have obtained the PSM1 and Scaled Agile framework 
        (SAFe) certification. I am currently studying to acquire the Azure 
        Fundamentals certification which will help further my knowledge of 
        Azure cloud based solutions to help me further in my day to day work. 
        My new role involves some travel so I will be going to Dubai in the summer!</p>

      <h2 className="blog-title">What difference has taking the training made to your life?</h2>
      <p>Quality of life has definitely improved for sure! It has taught me that 
        you have to constantly challenge yourself if you want to get to the next level.</p>
      <p>The training has opened me out to a career that I didn't even know was 
        possible for me 3 years ago. Best decision I have ever made.</p>

      <h2 className="blog-title">What advice would you give to anyone thinking 
        about changing careers & applying for Agile training with Lokem?</h2>
      <p>Agile training at Lokem will be the best decision you will ever make. 
        If you are thinking about changing your career and getting into tech 
        then this is the place to do it.</p>
      <p>Do not even think for one second that you need to have IT experience to 
        do well here because you really don't.</p>
      <p>Have a positive attitude and be relentless in your pursuit when you join 
        Lokem and success is almost guaranteed.</p>
      <p>For those that think they are too old or too young to do this course - 
        do not limit yourself! I have worked with Scrum Masters who are much 
        younger than me and I am 28. The oldest Scrum Masters I have seen are well 
        into their 50s - so age is nothing but a number. If you are driven you will 
        succeed no matter what.</p>

      <h2 className="blog-title">What is your biggest achievement that you are proud of?</h2>
      <p>I am proud of actually becoming a Scrum Master in my 1st role considering 
        the fact that I wasn't even that confident when I first started at Lokem. 
        I had a bit of imposter syndrome initially but I overcame it when the time 
        for interviews came round and I gave my all and succeeded.</p>

      <h2 className="blog-title">What are you doing to ensure you continue to grow 
        and develop in the world of tech?</h2>
      <p>I have obtained the PSM1 and SAFe certifications and am currently studying 
        or the Azure Fundamentals certification. I follow several Agile YouTubers 
        like “your agile coach” and “ scrummastered” who provide regular tips and 
        insights on how to become a better Agile professional.</p>

      <h2 className="blog-title">Do you want to know more about Simi? Follow him on LinkedIn</h2>
      <a href="https://www.linkedin.com/in/simi-oremosu-6894b0151" target="_blank" rel="noopener noreferrer">
        https://www.linkedin.com/in/simi-oremosu-6894b0151
      </a>
    </div>
  );
};

export default SimiJourney;
