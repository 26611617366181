import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/scrumMaster.scss";

//Section 2
import img1 from "../assets/images/training1.jpeg";
import img2 from "../assets/images/training2.jpeg";
//Section 3
import img3 from "../assets/images/sm-experts.png";
import img4 from "../assets/images/sm-mindset.png";
import img5 from "../assets/images/sm-agility.png";
import img6 from "../assets/images/sm-delivery.png";
//Section 5
import img8 from "../assets/images/sm-jouney-map.png";

const scrumMaster = () => {
  return (
    <div>
      {/* Banner */}
      <div className="scrum-master">
        <div className="scrum-master-banner">
          <div className="overlay">
            <h1>Scrum Master Training</h1>

            <Link to="/scrumMasterPD">
              {" "}
              <button className="book-now">Book now</button>
            </Link>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="training-overview-SM">
        <h2>Training Overview</h2>
        <div className="overview-content">
          <div className="text-content">
            <p>
              With our Scrum Master Course, you will learn to apply Scrum
              principles and values to various types of teamwork and you will
              implement the practical aspects of Scrum by working on live
              projects. We don't want you just to learn Scrum, we want you to
              succeed in it.
            </p>
            <p>
              At Lokem, our training helps everyone understand the Scrum
              framework and provides a level of knowledge that allows people to
              be more efficient and effective in its practise.
            </p>
            <p>
              This training is for leaders, managers, and other professionals
              seeking to gain in-depth knowledge on Scrum and learn how to
              revolutionise their way of delivering projects.
            </p>
            <p>
              The training is also suitable for anyone who is new to
              implementing Scrum.
            </p>
            <p>
              Looking for a change in career? New to Project Management? No
              prior IT knowledge or background? No problem. We give you all the
              skills and knowledge to succeed.
            </p>
            <p>
              We coach, mentor and guide you in becoming a Professional Scrum
              Master.
            </p>
          </div>
          <div className="images">
            <img src={img1} alt="Overview 1" />
            <img src={img2} alt="Overview 2" />
          </div>
        </div>
      </div>

      {/* Section 3*/}
      <div className="training-objectives-SM">
        <h2>Training Objectives</h2>
        <h3>Students learn to:</h3>
        <div className="objectives-content">
          <div className="objective">
            <img src={img3} alt="Expert on Agile framework" />
            <p>Be experts in the popular Agile framework.</p>
          </div>
          <div className="objective">
            <img src={img4} alt="Adopt a agile mindset" />
            <p>
              Adopt an Agile mindset and achieve success in helping
              organisations evolve.
            </p>
          </div>
          <div className="objective">
            <img src={img5} alt="Make a difference" />
            <p>
              Make a difference in their company’s current or future Agile
              transformation.
            </p>
          </div>
          <div className="objective">
            <img src={img6} alt="Lead a team" />
            <p>Build, mentor and lead a team in a real-life Scrum project.</p>
          </div>
        </div>
      </div>

      {/* Section 4 */}
      <div className="what-will-you-learn-SM">
        <div className="column-1">
          <h2>What Will You Learn?</h2>
          <p>
            You will learn how to apply Scrum practises such as coaching your
            team to be highly efficient and guiding them to success. The
            Professional Pathway Scrum Master Course is available for all, there
            are no prerequisites needed in terms of your current field.
          </p>
          <h3>Modules Covered</h3>
          <ul>
            <li className="underline">Project Initiation</li>
            <li className="underline">Business Analysis</li>
            <li className="underline">Project Management</li>
            <li className="underline">Scrum</li>
            <li className="underline">Scrum Roles and Responsibilities</li>
            <li className="underline">Scrum Artefacts</li>
            <li className="underline">Scrum Ceremonies</li>
            <li className="underline">Agile Metrics</li>
            <li className="underline">Agile Management Tools</li>
          </ul>

          <h3 className="gold-title">Who Facilitates The Course</h3>
          <p>
            Our training workshops are delivered by expert Agile consultants
            experienced in working at renowned global companies including
            Barclaycard, Lloyds Bank, Selfridges & Vodafone.
          </p>
        </div>

        <div className="column-2">
          <h3 className="training-title">Training Dates</h3>
          <p className="training-online">Location: online</p>
          <p className="training-text">
            The first three months of training are included in the cost (Package
            also includes access to work experience opportunities).
          </p>
          <p className="discount-code">
            Use code <span className="code-bold">AGILE50</span> for 50% off
          </p>
          <button className="book-now">Book Now</button>
        </div>
      </div>

      {/* Section 5 */}
      <div className="final-image">
        <img src={img8} alt="Final Image" />
      </div>
    </div>
  );
};

export default scrumMaster;
