import React from 'react';
import '../../assets/scss/blogs/procrastination.scss'

import img1 from '../../assets/images/blogs/psm1Badge.png'

import readBlog from "../../assets/images/blogs/readBlog.png";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

const Procrastination = () => {
  return (
    <div className="blog-procrastination">
      <section className="banner">
        <div className='overlay'>
        <h1>Beat procrastination and pass your PSM1 in no time</h1>
        </div>
      </section>

      <div className="content">
        <div className="image-text-row">
          <img src={img1} alt="Motivation" className="left-image" />
          <p>
          Procrastination is a complex phenomenon that can cause people to believe 
          that tasks are harder than they actually are, which can lead to further 
          procrastination, poor task planning and undue stress. When we procrastinate, 
          we tend to get distracted and become more susceptible to impulsiveness. Often 
          the root cause of procrastination is the fear of failure. This fear often stems 
          from a deep-rooted need for perfection. It’s natural to incubate and prepare; 
          however, that should only be a phase. When we seek perfection in fear of falling 
          short, we are really saying that ‘my sense of self cannot handle criticism and 
          so I’m not going to do anything that would put me in the firing line of being 
          scrutinised.’ The only real way to overcome the fear of failure and perfectionism 
          is to kill procrastination with action.
          </p>
        </div>

        <h2 className='blog-pro'>Get an Accountability Partner</h2>
        <p>I got a friend to hold me accountable; she contacted me every couple of days to 
          check in with me. It got tiring, very fast to keep finding excuses. In the end, 
          I wanted to be able to tell her I passed so much that I focused my energy on 
          passing just so that I could say I did.</p>

        <h2 className='blog-pro'>Remove all Obstacles</h2>
        <p>In the months leading up to my PSM1 random parking tickets and other 
          unexpected expenses were starting to pop up, and each time I would receive 
          one, I’d decide to push paying for my test a month later. Then I decided to 
          pay for the test weeks in advance and it no longer mattered what unexpected 
          payment came my way, I could still do the PSM1 whenever I wanted.</p>

        <h2 className='blog-pro'>Track your Work</h2>
        <p>Initially; I’d revise the scrum guide whenever I had time since I didn’t 
          keep track of what I was doing; it felt like I wasn’t doing much at all. My 
          confidence in my ability to pass the test was low; every time I would think 
          of the PSM1, I’d feel overwhelmed and agitated. This can often lead to avoidance 
          or indifference; what’s the point in doing anything if my scrum knowledge 
          feels like a drop in the ocean? I created a spreadsheet to track my test results; 
          I’d take a practice test, record the result and then go over the scrum guide. 
          I noted incorrect questions and revised the Scrum guide on what I found most 
          challenging during practice assessments. Some Product Owners may choose not to 
          create User Stories for Epics that are not considered a priority and will plan 
          the required features once necessary.</p>

        <h2 className='blog-pro'>Reach into your Experience</h2>
        <p>Nothing beats real-life experience, Scrum is based on empirical theory, and 
          so it comes to life when you see Scrum in action. The PSM1 test is designed for 
          professionals in the field and therefore some of the questions are framed in a 
          way that requires you to draw from experience. Allow real-life Scrum experiences 
          to guide you through the PSM1 test, reading the Scrum guide is not quite enough.</p>

        <h2 className='blog-pro'>Read the Scrum guide</h2>
        <p>Even though I’ve just said, reading the Scrum guide is not enough; you will 
          need to read “The Scrum Guide” and keep referring to it. Some PSM 1 questions 
          will come directly from the Scrum guide, literally word for word. The scrum guide 
          is comprehensive and can be difficult to take in all at once; you’ll need to review 
          the scrum guide quite regularly to understand it and then master it. When preparing 
          for my PSM1, I read the scrum guide once, put it down and got to work. Whenever 
          I encountered challenges in the workplace, I’d go back and review the scrum 
          guide. The more I used the Scrum guide as a reference to real-life experiences, 
          he more I could master it.</p>

        <h2 className='blog-pro'>Take Practice tests regularly</h2>
        <p>In preparation for my test, I took practice tests regularly. The questions 
          on each practise test can start to feel repetitive, and you’ll start to 
          memorise the questions and the answers. I jumped from one practice test to 
          another, for variety. In the final three weeks before my PSM1 test, I aimed 
          for a consistent 85% pass rate in the first week, then the next week moved 
          the target to 90% and when I started hitting a 95% rate on all practice tests 
          I knew I was ready to pass the test the first time.</p>
      {/* Blog Footer */}
      <div className="blog-foot">
          <div className="post">
            <img src={readBlog} alt="readblog" />
            <a href=""> &lt; Previous post</a>
          </div>
          <div className="rate-post">
            <p>
              Rate this Article <br />
              <strong>0 from 0 ratings</strong>
            </p>
            <p>Share with your network</p>
            <div className="sociallinks">
              <a href="https://www.facebook.com/lokem/" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </a>
              <a href="https://twitter.com/lokemuk" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href="https://www.linkedin.com/lokemuk?_l=en_US" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div className="post">
            <img src={readBlog} alt="readtheblog" />
            <a href="">Next post &gt;</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Procrastination;
