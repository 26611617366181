import React, { useState, useEffect } from 'react';
import '../assets/scss/cookies.scss';

const Cookies = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  type CookiePreferenceKeys = 'essential' | 'functional' | 'performance' | 'marketing' | 'analytics' | 'thirdParty';

  const [cookiePreferences, setCookiePreferences] = useState({
    essential: false,
    functional: false,
    performance: false,
    marketing: false,
    analytics: false,
    thirdParty: false,
  });

  const cookieDescriptions = {
    essential: "These are cookies required in order for a website to work and they do not collect any personal information. Lokem uses essential cookies to record when users have viewed cookie notices, and whether the user has granted permission for cookie tracking (essential & non-essential).",
    functional: "These cookies are used to remember choices you make to give you better functionality and personalisation. Lokem uses functional cookies to remember important information and user's preferences. If you do not allow these cookies then some or all of these services may not function properly.",
    performance: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular. All information these cookies collect are anonymous..",
    marketing: "Other companies may use cookies on our website, this is known as third party cookie collection. Additional cookies may be essential in order to make these features work correctly, but some companies may also include cookies for tracking and advertising.",
    analytics: "These are used to track how our website is performing. We use Google Analytics to track the number of visits we receive on our website, which specific pages have been visited, and for how long. This information is collected on an anonymous basis.",
    thirdParty: "Other companies may use cookies on our website, this is known as third-party cookie collection. Additional cookies may be essential in order to make these features work correctly, but some companies may also include cookies for tracking and advertising.",
  };

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handlePersonalise = () => {
    setIsExpanded(true);
  };

  const handleToggle = (type: CookiePreferenceKeys) => {
    setCookiePreferences((prevPreferences) => ({
      ...prevPreferences,
      [type]: !prevPreferences[type],
    }));
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={`cookie-banner ${isExpanded ? 'expanded' : ''}`}>
      {!isExpanded ? (
        <>
         <p>
          This website uses cookies to ensure you get the best browsing experience.
          For more information, read our
          <strong><u><a className = "privacy-word" href="./privacyPolicy">privacy policy</a></u></strong>.
        </p>
          <div className="cookie-buttons">
            <button onClick={handleAccept} className="accept-btn">Accept</button>
            <button onClick={handlePersonalise} className="personalise-btn">Personalise</button>
          </div>
        </>
      ) : (
        <div className="expanded-content">
          <h2>Manage my cookies</h2>
          <p>
            We use cookies to improve the experience. Choose what 
            cookies you allow us to use. You can read more about 
            our cookies policy on our <u>Privacy Policy</u>.
          </p>
          <div className="cookie-options">
            {Object.keys(cookiePreferences).map((type) => (
              <div className="cookie-option" key={type}>
                <div className="cookie-toggle">
                  <h3>{type === 'thirdParty' ? 'Third Party Cookies' : type.charAt(0).toUpperCase() + type.slice(1) + ' Cookies'}</h3>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={cookiePreferences[type as CookiePreferenceKeys]}
                      onChange={() => handleToggle(type as CookiePreferenceKeys)}
                    />
                    <span className="slider round"></span>
                    </label>
                    <span>{cookiePreferences[type as CookiePreferenceKeys] ? 'On' : 'Off'}</span>
                </div>
                <div className="cookie-text">
                  <p>{cookieDescriptions[type as CookiePreferenceKeys]}</p>
                </div>
              </div>
            ))}
          <div className="cookie-buttons">
            <button onClick={handleSavePreferences} className="button-common save-btn">
              Save My Preferences
            </button>
            <button onClick={handleAccept} className="button-common accept-btn">
              Accept All
            </button>
          </div>
        </div>
        </div>
      )}
    </div>
  );
};

export default Cookies;