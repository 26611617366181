import React from "react";
import { useState } from "react";
import "../assets/scss/scrumMasterPD.scss";

const ScrumMasterPD = () => {
  // State for form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    trainingDate: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    trainingDate: "",
  });

  // State for coupon validation
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");

  const validateField = (field: string, value: string) => {
    let errorMessage = "";
    switch (field) {
      case "name":
        errorMessage = value.trim() === "" ? "Please enter a valid name" : "";
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        errorMessage = !emailRegex.test(value)
          ? "Please enter a valid email address"
          : "";
        break;
      case "phone":
        const phoneRegex = /^\d{10,15}$/;
        errorMessage = !phoneRegex.test(value)
          ? "Please enter a valid phone number"
          : "";
        break;
      case "trainingDate":
        errorMessage = value === "" ? "Please choose a training date" : "";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: errorMessage }));
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (errors[name as keyof typeof errors]) {
      validateField(name, value); // Re-validate field if an error exists
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let valid = true;
    Object.keys(formData).forEach((field) => {
      if (formData[field as keyof typeof formData].trim() === "") {
        validateField(field, formData[field as keyof typeof formData]);
        valid = false;
      }
    });

    if (valid) {
      alert("Form submitted successfully!");
    }
  };

  // Handle coupon change
  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(e.target.value);
    setCouponError(""); // Clear error when user starts typing
  };

  // Validate coupon when applying
  const handleCouponApply = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (coupon.trim() === "") {
      setCouponError("Enter a valid coupon code");
    } else {
      alert("Coupon applied successfully!");
    }
  };

  return (
    <div>
      <div className="training-form">
        <h2>Scrum Master Training</h2>
        <div className="training-form-container">
          <div className="right-side">
            <h2>Personal Details</h2>
            <div className="form">
              <form onSubmit={handleSubmit}>
                <div className="inputfield">
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && (
                    <p className="error-message">{errors.name}</p>
                  )}
                </div>
                <div className="inputfield">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address (info@lokem.co.uk)"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
                <div className="inputfield">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  />
                  {errors.phone && (
                    <p className="error-message">{errors.phone}</p>
                  )}
                </div>
                <div className="inputfield">
                  <select
                    name="trainingDate"
                    value={formData.trainingDate}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Please select training date</option>
                    <option value="5th & 6th Oct 2024">
                      5th & 6th Oct 2024
                    </option>
                    <option value="2nd & 3rd Nov 2024">
                      2nd & 3rd Nov 2024
                    </option>
                  </select>
                  {errors.trainingDate && (
                    <p className="error-message">{errors.trainingDate}</p>
                  )}
                </div>
              </form>

              {/* Line */}
              <div className="line"></div>

              <div className="payment-schedule">
                <h2>Payment Schedule</h2>
                <p className="non-refundable">This payment is non refundable</p>
                <div className="options">
                  <label className="option">
                    <input type="radio" name="paymentOption" value="full" />
                    Full Payment (£1500)
                  </label>
                  <label className="option">
                    <input type="radio" name="paymentOption" value="deposit" />
                    Deposit (£375)
                  </label>
                </div>
              </div>

              {/* Line */}
              <div className="line"></div>

              {/* Coupon Section */}
              <div className="coupon-section">
                <h2>Coupon</h2>
                <div className="coupon-input">
                  <input
                    type="text"
                    placeholder="Coupon"
                    value={coupon}
                    onChange={handleCouponChange}
                  />
                  <a href="/" onClick={handleCouponApply}>
                    Apply Coupon
                  </a>
                </div>
                {couponError && <p className="error-message">{couponError}</p>}
              </div>
            </div>
          </div>
          <div className="left-side">
            <h4 className="lsh4">Included In Purchase</h4>
            <ul>
              <li>
                3 months Scrum training program 5th & 6th Oct 2024 10:00 - 17:00
                GMT
              </li>
              <li>3 months remote work experience in Scrum environment</li>
              <li>Access to Scrum resources and training from agile coaches</li>
              <li>Certificate of attendance</li>
            </ul>

            <div className="amount-section">
              <h3>Amount</h3>
              <h5>Total Amount £1500</h5>
              <div className="use-code">
                Use code <span className="coupon-code">AGILE50</span> for 50%
                off
              </div>

              <div className="payment">
                <button type="submit" className="payment-btn">
                  Proceed to Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrumMasterPD;
