export const ENVEnum: any = {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
  };
  
  export const Environment: string = process.env.NODE_ENV || ENVEnum.DEVELOPMENT;
  console.log("window.location.hostname", window.location.hostname);
  
  // base path for APIs
  export const API_ENDPOINT: string =
    Environment === ENVEnum.DEVELOPMENT
      ? 'http://localhost:8007/student/api/v2'
      : `/student/api/v2`;
  
  // base path for images
  
  export const ImageURL: string =
    Environment === ENVEnum.DEVELOPMENT
      ? `${window.location.protocol}//${window.location.hostname}:8007/student/`
      : `${window.location.protocol}://${window.location.hostname}/student`;
  export const FrontendUrl: string =
    Environment === ENVEnum.DEVELOPMENT 
    ? `${window.location.protocol}//${window.location.hostname}:8007/student/` 
    : `${window.location.protocol}//${window.location.hostname}/student/`;
  
  export function getEnvValue() {
    var host = window.location.host; 
    var envValue = (host.includes("lo-kem") || host.includes("lokem")) ? "live" : "test";
    return envValue;
  }