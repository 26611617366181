import React from "react";
import "../assets/scss/paymentConfirmation.scss";

const PaymentConfirmation = () => {
  return (
    <div>
      <div className="banner">
        <div className="banner-content">
          <h1 className="banner-title">
            Thank you for completing your online payment. <br /> An email
            confirmation will be sent to you shortly.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
