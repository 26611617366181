import { IApiRoutes } from "../interfaces/apiRoutes.interface";

export const ApiRoutes: IApiRoutes = {
  HELP_FAQ_DATA: {
    service: "/helpFaq",
    url: "/getHelpFaqData",
    method: "GET",
    authenticate: true,
  },
};

