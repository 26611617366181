import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/projectManagerTraining.scss";
import img1 from "../assets/images/po-training1.jpg";
import img4 from "../assets/images/po-expertise.png";
import img5 from "../assets/images/po-mindset.png";
import img6 from "../assets/images/po-agility.png";
import img7 from "../assets/images/po-delivery.png";
//import img8 from "../assets/images/po-training2.jpg";

const ProjectManagerTraining = () => {
  return (
    <div>
      {" "}
      {/* Banner */}
      <div className="project-manager">
        <div className="project-manager-banner">
          <div className="overlay">
            <h1>Become a Certified Project Manager</h1>
          </div>
          <div className="button-container">
            <Link to="/personaldetails">
              {" "}
              <button className="book-now">Book now</button>
            </Link>
          </div>
        </div>
      </div>
      {/* Section 2 */}
      <div className="training-overview-PM">
        <h2>Training Overview</h2>
        <div className="overview-content">
          <div className="text-content">
            <p>
              The purpose of this Project Manager Training is to give
              participants the information and abilities needed to oversee
              large-scale projects effectively, from the planning and
              development stage all the way through to the deployment and
              completion stage.
            </p>
            <p>
              Project execution, control, closure, planning, risk management,
              stakeholder management and team dynamics are just a few of the
              subjects covered in this course. Along with introducing various
              project management methodologies, the course aids in the
              development of student's personal project management abilities and
              expertise.
            </p>
          </div>
          <div className="images">
              <img src={img1} alt="Overview 1" />
          </div>
        </div>
      </div>
      {/* Section 3*/}
      <div className="training-objectives-PM">
        <h2>Training Objectives</h2>
        <h3>After completing the course, students ought to be able to:</h3>
        <div className="objectives-content">
          <div className="objective">
            <img src={img4} alt="Identify core concepts" />
            <p>
              Identify the core concepts, processes and practices of project
              management. Utilize the PMP methodology and its tools to plan and
              manage projects
            </p>
          </div>
          <div className="objective">
            <img src={img5} alt="Understand the roles" />
            <p>
              Understand the roles and responsibilities of stakeholders in
              project management.
            </p>
          </div>
          <div className="objective">
            <img src={img6} alt="Apply earned value concepts" />
            <p>
              Apply earned value concepts to successful projects. Examine
              project risk management and how to identify and manage project
              risks.
            </p>
          </div>
          <div className="objective">
            <img src={img7} alt="Improve quality control" />
            <p>
              Improve quality control practices by developing an understanding
              of quality management and improvement. Learn how to report
              progress, develop project closure reports and activate key project
              lessons learned.
            </p>
          </div>
        </div>
      </div>
      {/* Section 4 */}
      <div className="what-will-you-learn-PM">
        <div className="column-1">
          <h2>What Will You Learn?</h2>
          <p>
            By working on live projects and gaining hands on experience you will
            constantly be learning and developing the essential skills of a
            Product Owner in order to become PSPO certified. You will gain good
            knowledge of product ownership, including:
          </p>
          <h3>Modules Covered</h3>
          <ul>
            <li className="underline">Project Management</li>
            <li className="underline">Project Integration Management</li>
            <li className="underline">Project Scope Management</li>
            <li className="underline">Project Schedule Management</li>
            <li className="underline">Project Cost Management</li>
            <li className="underline">Project Quality Management</li>
            <li className="underline">Project Resource Management</li>
            <li className="underline">Project Communication Management</li>
            <li className="underline">Project Risk Management</li>
            <li className="underline">Project Procurement Management</li>
            <li className="underline">Project Stakeholder Management</li>
          </ul>

          <h3 className="gold-title">Who Facilitates The Course?</h3>
          <p>
            Our training workshops are delivered by expert Agile consultants
            working at globally renowned companies including Barclaycard, Lloyds
            Bank, Selfridges & Vodafone.
          </p>
        </div>

        <div className="column-2-wrapper-PM">
          <div className="column-2">
            <h3 className="training-title">Training Dates</h3>
            <p>Location: online</p>
            <p>
              Followed by a minimum of 3 months online training (Package also
              includes access to work experience opportunities)
            </p>
            <p className="discount-code-PM">
            Use code <span className="code-bold-PM">AGILE50</span> for 50% off
            </p>
            <button className="book-now">Book Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManagerTraining;
