import React, { useState } from "react";

const Home = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
      <h1>Navigation links</h1>
      <div>
        <a href="/aboutUs">aboutUs</a>
      </div>
      <div>
        <a href="/contactUs">contactUs</a>
      </div>
      <div>
        <a href="/testimonials">Testimonials</a>
      </div>
      <div>
        <a href="/FaqPage">faqPage</a>
      </div>
      <div>
        <a href="/corporateTraining">Corporate Training</a>
      </div>
      <div>
        <a href="/individualTraining">Individual Training</a>
      </div>
      <div>
        <a href="/blogs">Blogs</a>
      </div>
      <div>
        <a href="/projectManagement">Project Manager Training</a>
      </div>
      <div>
        <a href="/homepage">Home Page</a>
      </div>
      <div>
        <a href="/Payment">Payment Details</a>
      </div>
      <div>
        <a href="/paymentconfirmation">Payment confirmation</a>
      </div>
      <div>
        <a href="/personaldetails">
          Project Manager Training Personal Details Page
        </a>
      </div>
      <div>
        <a href="/productOwnerPD">
          Product Owner Training Personal Details Page
        </a>
      </div>
      <div>
        <a href="/scrumMasterPD">Scrum Master Training Personal Details Page</a>
      </div>
      <div>
        <a href="/businessAnalystPD">
          Business Analyst Training Personal Details Page
        </a>
      </div>
      <div>
        <a href="/adminLogin">Admin Login</a>
      </div>
      <div>
        <a href="/trainees">Trainees</a>
      </div>
    </div>
  );
};

export default Home;
