import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/productOwner.scss";
import img1 from "../assets/images/po-training1.jpg";
import img2 from "../assets/images/training3.jpeg";
import img4 from "../assets/images/po-expertise.png";
import img5 from "../assets/images/po-mindset.png";
import img6 from "../assets/images/po-agility.png";
import img7 from "../assets/images/po-delivery.png";
import img8 from "../assets/images/po-training2.jpg";
import img9 from "../assets/images/po-journey-map.png";

const productOwner = () => {
  return (
    <div>
      {/* Banner */}
      <div className="product-owner">
        <div className="product-owner-banner">
          <div className="overlay">
            <h1>Become a Certified Product Owner</h1>
            <Link to="/productOwnerPD">
              {" "}
              <button className="book-now">Book now</button>
            </Link>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="training-overview-PO">
        <h2>Training Overview</h2>
        <div className="overview-content">
          <div className="text-content">
            <p>
              Study with us to become a Product Owner. Give your career a boost
              by embarking on a training course that employers around the world
              respect, value and most importantly need.
            </p>
            <p>
              In studying this course you will learn product ownership within
              Scrum and how to maximise the value of products and increase
              business agility through the role of the Product Owner. Product
              Owners motivate Scrum Teams to deliver a high-quality product,
              while setting the team’s priorities and the scope for the product.
              Throughout the duration of the course, we instill Agile and Scrum
              values in our trainees, setting them on the path to unwavering
              success as Product Owners.
            </p>
            <p>
              This training is for leaders, managers, and other professionals
              seeking to gain in-depth knowledge on Product Ownership and learn
              how to revolutionise their way of delivering products.
            </p>
            <p>
              Looking for a change in career? New to Product Management? No
              prior IT knowledge or background? No problem! We will give you all
              the skills & knowledge you need to succeed.
            </p>
            <p>
              We coach, mentor and guide you in becoming a Professional Product
              Owner.
            </p>
          </div>
          <div className="images">
            <div className="image-1">
              <img src={img1} alt="Overview 1" />
            </div>
            <div className="image-2">
              <img src={img2} alt="Overview 2" />
            </div>
          </div>
        </div>
      </div>

      {/* Section 3*/}
      <div className="training-objectives-PO">
        <h2>Training Objectives</h2>
        <h3>Students will learn how to:</h3>
        <div className="objectives-content">
          <div className="objective">
            <img src={img4} alt="Expert on Agile framework" />
            <p>
              Become an expert in the Scrum framework and develop an
              understanding of the critical role the Product Owner plays in a
              Scrum Team.
            </p>
          </div>
          <div className="objective">
            <img src={img5} alt="Adopt a agile mindset" />
            <p>
              Increase the agile transformation of your current or future
              employers through the correct execution of the Product Owner role.
            </p>
          </div>
          <div className="objective">
            <img src={img6} alt="Make a difference" />
            <p>
              Adopt a product over project mindset in the role of a Product
              Owner.
            </p>
          </div>
          <div className="objective">
            <img src={img7} alt="Lead a team" />
            <p>
              Learn how to deliver high quality products in real-life Scrum
              projects.
            </p>
          </div>
        </div>
      </div>

      {/* Section 4 */}
      <div className="what-will-you-learn-PO">
        <div className="column-1">
          <h2>What Will You Learn?</h2>
          <p>
            By working on live projects and gaining hands on experience you will
            constantly be learning and developing the essential skills of a
            Product Owner in order to become PSPO certified. You will gain good
            knowledge of product ownership, including:
          </p>
          <h3>Modules Covered</h3>
          <ul>
            <li className="underline">
              The accountabilities of the Product Owner role in Scrum
            </li>
            <li className="underline">
              The importance of a product over project mind-set
            </li>
            <li className="underline">
              How to increase business agility through the Product Owner role
            </li>
            <li className="underline">
              Effective product backlog management techniques to aid developers
              in producing increments of quality
            </li>
            <li className="underline">
              How to effectively communicate business strategies, the product
              vision, product goal and product roadmap
            </li>
            <li className="underline">
              How to write user stories with acceptance criteria
            </li>
            <li className="underline">
              The importance of Empiricism and Scrum principles
            </li>
            <li className="underline">
              To understand, apply and master the full range of Scrum Artefacts
            </li>
            <li className="underline">
              Stakeholder management and collaboration techniques
            </li>
            <li className="underline">
              Identify and implement metrics to track the progress of value and
              successful product delivery
            </li>
          </ul>

          <h3 className="gold-title">Who Facilitates The Course?</h3>
          <p>
            Our training workshops are delivered by expert Agile consultants
            experienced in working at renowned global companies including
            Barclaycard, Lloyds Bank, Selfridges & Vodafone.
          </p>
        </div>

        <div className="column-2-wrapper-PO">
          <div className="column-2-PO">
            <h3 className="training-title">Training Dates</h3>
            <p>Location: online</p>
            <p>
              Followed by a minimum of 3 months online training (Package also
              includes access to work experience opportunities)
            </p>
            <p className="discount-code-PO">
              Use code <span className="code-bold-PO">AGILE50</span> for 50% off
            </p>
            <button className="book-now-PO">BOOK NOW</button>
          </div>

          <div className="column-2-image">
            <img src={img8} alt="Final Image" />
          </div>
        </div>
      </div>

      {/* Section 5 */}
      <div className="final-image">
        <img src={img9} alt="Final Image" />
      </div>
    </div>
  );
};

export default productOwner;
