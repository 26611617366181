import React from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/blogs/blogs.scss";
import blogimg1 from "../../assets/images/blogs/blogs1.png";
import blogimg2 from "../../assets/images/blogs/blogs2.jpg";
import blogimg3 from "../../assets/images/blogs/blogs3.jpg";
import blogimg4 from "../../assets/images/blogs/blogs4.jpg";
import blogimg5 from "../../assets/images/blogs/blogs5.png";
import blogimg6 from "../../assets/images/blogs/blogs6.jpg";
import blogimg7 from "../../assets/images/blogs/blogs7.png";
import blogimg8 from "../../assets/images/blogs/blogs8.jpeg";
import blogimg9 from "../../assets/images/blogs/blogs9.jpg";
import blogimg10 from "../../assets/images/blogs/blogs10.jpeg";
import blogimg11 from "../../assets/images/blogs/blogs11.png";

const Blogs = () => {
  const blogPosts = [
    {
      id: 1,
      title: "Simi's journey: Project Assistant to Agile Delivery Manager",
      description:
        "Agile training at Lokem will be the best decision you will ever make. If you are thinking about changing your career and getting into tech then this is the place to do it.",
      readTime: "(5 Minute Read)",
      date: "11 Dec 2023",
      imageUrl: blogimg1,
      link: "/simijourney",
    },
    {
      id: 2,
      title: "How will Scrum benefit your team?",
      description:
        "As the most popular Agile framework used in Project Management, Scrum focuses on completing work in small iterations, consistently inspecting and adapting along the way. Scrum is a simple framework which is relatively easy to implement.",
      readTime: "(4 Minute Read)",
      date: "22 May 2021",
      imageUrl: blogimg2,
      link: "/scrumbenefit",
    },
    {
      id: 3,
      title: "Striving to Serve: How to become an Effective Leader",
      description:
        "Imagine a leader who doesn't dictate but acts as a guide, removing obstacles and nurturing growth. This approach fuels a culture of autonomy where team members flourish. The beauty lies in its simplicity – by putting the team's needs first.",
      readTime: "(4 Minute Read)",
      date: "11 Jan 2024",
      imageUrl: blogimg3,
      link: "/effectiveleader",
    },
    {
      id: 4,
      title: "Beat procrastination and pass your PSM1 in no time",
      description:
        "I got a friend to hold me accountable; she contacted me every couple of days to check in with me. It got tiring, very fast to keep finding excuses. In the end, I wanted to be able to tell her I passed so much that I focused my energy on passing just so that I could say I did.",
      readTime: "(3 Minute Read)",
      date: "04 April 2021",
      imageUrl: blogimg4,
      link: "/procrastination",
    },
    {
      id: 5,
      title: "How to go from an Epic to User Story",
      description:
        "An Epic is essentially the ‘Big Idea’ of the project. For instance, spring cleaning could be considered an Epic as it is an event that has multiple layers that cannot all be completed simultaneously. Therefore, this Epic will require it to be broken down further into User Stories to make it manageable.",
      readTime: "(2 Minute Read)",
      date: "22 May 2021",
      imageUrl: blogimg5,
      link: "/userstory",
    },
    {
      id: 6,
      title: "How To Implement Scrum In Non-Software Teams?",
      description:
        "If you've come this far, you probably have a rough idea of what Scrum is and that it's pretty popular in the world of software development.",
      readTime: "(5 Minute Read)",
      date: "01 Mar 2024",
      imageUrl: blogimg6,
      link: "/implementscrum",
    },
    {
      id: 7,
      title: "Does the Agile culture need more emotional intelligence?",
      description:
        "With the pandemic in the rear mirror, companies are realising the importance of adapting and modifying their business structures in order to be ready to face constant and unpredictable challenges.",
      readTime: "(5 Minute Read)",
      date: "20 Apr 2024",
      imageUrl: blogimg7,
      link: "/agileculture",
    },
    {
      id: 8,
      title: "Top five performing agile companies",
      description:
        "In a world where the business landscape is moving expeditiously, an undefiable key driver in success is agility. For companies who are striving to keep ahead, the ability to adapt..",
      readTime: "(3 Minute Read)",
      date: "25 Apr 2024",
      imageUrl: blogimg8,
      link: "/topfiveagile",
    },
    {
      id: 9,
      title: "How To Ace Your Interview With The Use Of The STAR Technique?",
      description:
        'Interviews can be daunting, stressful and unpredictable. For these reasons alone, it is important to minimise the effect these could have on you by preparing in advance. As with many things in life, "Preparation is Key" and an interview is by no means an exception.',
      readTime: "(5 Minute Read)",
      date: "01 May 2024",
      imageUrl: blogimg9,
      link: "/aceyourinterview",
    },
    {
      id: 10,
      title: "Sprint Retrospective",
      description:
        "The Sprint Retrospective is a Scrum event held at the end of each Sprint which evaluates what went well during the Sprint and identifies areas for improvement..",
      readTime: "(4 Minute Read)",
      date: "17 May 2024",
      imageUrl: blogimg10,
      link: "/sprintreview",
    },
    {
      id: 11,
      title: "How did the Coronavirus pandemic impact the tech industry?",
      description:
        "The Coronavirus pandemic brought about a significant change in various industries, especially in the ever-evolving field of technology.",
      readTime: "(5 Minute Read)",
      date: "01 May 2024",
      imageUrl: blogimg11,
      link: "/coronaviruspandemic",
    },
  ];
  return (
    <div>
      <div className="blogs">
        {/* Banner Section */}
        <div className="blogs-banner">
          <div className="overlay">
            <h1>Blogs</h1>
          </div>
        </div>
      </div>{" "}
      {/* Blog Posts Section */}
      <div className="blog-wrapper">
        <div className="blogPage">
          <p className="blog-heading">
            Welcome to our blog page. You'll find our writings, latest news and
            case studies here, plus the latest in all-important technology.
          </p>
          {blogPosts.map((post, index: number) => (
            <div
              key={post.id}
              className={`blogPost ${index % 2 === 1 ? "reverse" : ""}`}
            >
              <div className="blogPost__image">
                <img src={post.imageUrl} alt={post.title} />
              </div>
              <div className="blogPost__content">
                <h2>{post.title}</h2>
                <p>{post.description}</p>
                <div className="bottom-div">
                  <div className="blogPost__info">
                    <span className="time">{post.readTime}</span>
                    <span className="date">{post.date}</span>
                  </div>
                  <Link to={post.link} className="blogPost__button">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
