import "../assets/scss/homePage.scss";
import React, { useState } from "react";

import S2img1 from "../assets/images/pathway-img2.jpg";
import S2img2 from "../assets/images/pathway-img1.jpg";
import S2img3 from "../assets/images/pathway-img3.jpg";
import S2img4 from "../assets/images/pathway-img4.jpg";

import S3img1 from "../assets/images/home-earn.gif";
import S3img2 from "../assets/images/home-training.gif";
import S3img3 from "../assets/images/home-mentor.gif";
import S3img4 from "../assets/images/home-cv.gif";
import S3img5 from "../assets/images/home-certified.png";
import S3img6 from "../assets/images/home-job.gif";

import S4img1 from "../assets/images/voice1.jpg";
import S4img2 from "../assets/images/voice2.jpg";
import S4img3 from "../assets/images/voice3.jpg";
import S4audio1 from "../assets/audio/tm-ar1.mp3";
import S4audio2 from "../assets/audio/tm-ar2.mp3";
import S4audio3 from "../assets/audio/tm-ar3.mp3";

import S5img1 from "../assets/images/homepage-sarah.jpg";
import S5img2 from "../assets/images/homepage-sandra.jpg";
import S5openQuote from "../assets/images/quote.jpg";
import S5closeQuote from "../assets/images/quote-bottom.jpg";
import S5rightarrow from "../assets/images/greaterthan.png";
import S5leftarrow from "../assets/images/lessthan.png";

import S6img1 from "../assets/images/logos/1.jpg";
import S6img2 from "../assets/images/logos/2.jpg";
import S6img3 from "../assets/images/logos/3.jpg";
import S6img4 from "../assets/images/logos/4.jpg";
import S6img5 from "../assets/images/logos/5.jpg";
import S6img6 from "../assets/images/logos/6.jpg";
import S6img7 from "../assets/images/logos/7.jpg";
import S6img8 from "../assets/images/logos/8.jpg";
import S6img9 from "../assets/images/logos/9.jpg";
import S6img10 from "../assets/images/logos/10.jpg";
import S6img11 from "../assets/images/logos/11.jpg";
import S6img12 from "../assets/images/logos/12.jpg";
import S6img13 from "../assets/images/logos/13.jpg";
import S6img14 from "../assets/images/logos/14.jpg";
import S6img15 from "../assets/images/logos/15.jpg";
import S6img16 from "../assets/images/logos/16.jpg";
import S6img17 from "../assets/images/logos/17.jpg";
import S6img18 from "../assets/images/logos/18.jpg";
import S6img19 from "../assets/images/logos/19.jpg";
import S6img20 from "../assets/images/logos/20.jpg";
import S6img21 from "../assets/images/logos/21.jpg";
import S6img22 from "../assets/images/logos/22.jpg";
import S6img23 from "../assets/images/logos/23.jpg";
import S6img24 from "../assets/images/logos/24.jpg";
import S6img25 from "../assets/images/logos/25.jpg";
import S6img26 from "../assets/images/logos/26.jpg";
import S6img27 from "../assets/images/logos/27.jpg";
import S6img28 from "../assets/images/logos/28.jpg";
import S6img29 from "../assets/images/logos/29.jpg";
import S6img30 from "../assets/images/logos/30.jpg";
import S6img31 from "../assets/images/logos/31.jpg";
import S6img32 from "../assets/images/logos/32.jpg";

import S7img1 from "../assets/images/phone.png";
import S7img2 from "../assets/images/email.png";

// form //
interface FormState {
  name: string;
  email: string;
  contactNumber: string;
  interest: string;
}

// Logo Slider
const logos = [
  { id: 1, src: S6img1, alt: "Brand 1" },
  { id: 2, src: S6img2, alt: "Brand 2" },
  { id: 3, src: S6img3, alt: "Brand 3" },
  { id: 4, src: S6img4, alt: "Brand 4" },
  { id: 5, src: S6img5, alt: "Brand 5" },
  { id: 6, src: S6img6, alt: "Brand 6" },
  { id: 7, src: S6img7, alt: "Brand 7" },
  { id: 8, src: S6img8, alt: "Brand 8" },
  { id: 9, src: S6img9, alt: "Brand 9" },
  { id: 10, src: S6img10, alt: "Brand 10" },
  { id: 11, src: S6img11, alt: "Brand 11" },
  { id: 12, src: S6img12, alt: "Brand 12" },
  { id: 13, src: S6img13, alt: "Brand 13" },
  { id: 14, src: S6img14, alt: "Brand 14" },
  { id: 15, src: S6img15, alt: "Brand 15" },
  { id: 16, src: S6img16, alt: "Brand 16" },
  { id: 17, src: S6img17, alt: "Brand 17" },
  { id: 18, src: S6img18, alt: "Brand 18" },
  { id: 19, src: S6img19, alt: "Brand 19" },
  { id: 20, src: S6img20, alt: "Brand 20" },
  { id: 21, src: S6img21, alt: "Brand 21" },
  { id: 22, src: S6img22, alt: "Brand 22" },
  { id: 23, src: S6img23, alt: "Brand 23" },
  { id: 24, src: S6img24, alt: "Brand 24" },
  { id: 25, src: S6img25, alt: "Brand 25" },
  { id: 26, src: S6img26, alt: "Brand 26" },
  { id: 27, src: S6img27, alt: "Brand 27" },
  { id: 28, src: S6img28, alt: "Brand 28" },
  { id: 29, src: S6img29, alt: "Brand 29" },
  { id: 30, src: S6img30, alt: "Brand 30" },
  { id: 31, src: S6img31, alt: "Brand 31" },
  { id: 32, src: S6img32, alt: "Brand 32" },
];

const HomePage = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const pages = [
    {
      profileImage: S5img1,
      quote: [
        "Their E-Learning platform is amazing!",
        "Before I joined Lokem, I didn’t know what Scrum was. I attended the two-day training with Bisi and Aminat, which was very informative and engaging! From feeling lost at the start to feeling confident - the training I received allowed me to begin my journey on becoming a Scrum Master with ease!",
        "If you’re thinking of getting into Scrum Master training, I would highly recommend Lokem. I chose this company because they provide so much useful information, especially the reality of work life. I also got to work on live projects and do work experience.",
        "I’ve been using the online platform for about 4 months now, and I can honestly say that it has allowed me to develop my skill set and knowledge. I also love the fact that Lokem offers continuous development for all their students - which means we get training and mentoring by their Agile coaches and experienced professionals.",
        "I would definitely recommend Lokem to anyone looking for a career change and wanting the right support. A career in Scrum is rewarding, interesting and pays well!",
        "Sarah O",
      ],
    },
    {
      profileImage: S5img2,
      quote: [
        "Thank you Lokem, for helping me get the Scrum job!",
        "Lokem’s training courses exceeded my expectations. I was really impressed by the level of experience that I got during my time with them. The community was so supportive - they helped me grow and learn what I wanted to do with my career. My mentor, Femi, helped me every step of the way, giving me a clear road map to my career path in Scrum.",
        "My work experience at Lokem was way more immersive and relevant to the current market than my previous training provider, which has been very helpful for me in my new role.",
        "",
        "",
        "Sandra",
      ],
    },
  ];

  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [formData, setFormData] = useState<FormState>({
    name: "",
    email: "",
    contactNumber: "",
    interest: "",
  });

  const [errors, setErrors] = useState<FormState>({
    name: "",
    email: "",
    contactNumber: "",
    interest: "",
  });

  const validateForm = () => {
    let isValid = true;
    let errors = { name: "", email: "", contactNumber: "", interest: "" };

    if (!formData.name.trim()) {
      errors.name = "Please enter a valid name";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    const phoneRegex = /^[0-9]{10,15}$/;
    if (
      !formData.contactNumber.trim() ||
      !phoneRegex.test(formData.contactNumber)
    ) {
      errors.contactNumber = "Please enter a valid phone number";
      isValid = false;
    }

    if (!formData.interest) {
      errors.interest = "Please choose your interest";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Form submitted:", formData);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div>
      <div className="banner">
        <div className="banner-content">
          <h1 className="banner-title">
            We help people become agile experts through training, certification,
            mentoring, and hands-on job experience.
          </h1>
          <h2 className="banner-subtitle">
            Individuals successfully change their career with our proven
            training program.
          </h2>
        </div>
      </div>
      {/* Section 2 */}
      <div className="cards-section">
        <div className="card">
          <h3 className="card-title">Scrum Master Training</h3>
          <img
            src={S2img1}
            alt="Scrum Master Training"
            className="card-image"
          />
          <p className="card-info">
            The Scrum Master is the servant leader of the team. They ensure that
            the team lives agile values and principles while also following
            Scrum practices - by clearing impediments and blockers they enable
            the team to deliver ...
            <a href="/ScrumMaster" className="card-link">
              Find out more
            </a>
          </p>
          <p className="card-discount">
            Use code <strong> AGILE50</strong> for 50% off
          </p>
          <button className="card-button">Book Now</button>
        </div>
        <div className="card">
          <h3 className="card-title">Business Analyst Training</h3>
          <img
            src={S2img2}
            alt="Scrum Master Training"
            className="card-image"
          />
          <p className="card-info">
            Business Analysts (BA) support Product Owners with analysis,
            addressing customers’ needs and facilitating pressing dialogues. Our
            course teaches you how BA’s support the Scrum team by utilising the
            process of empiric...
            <a href="/BusinessAnalyst" className="card-link">
              Find out more
            </a>
          </p>
          <p className="card-discount">
            Use code <strong> AGILE50</strong> for 50% off
          </p>
          <button className="card-button">Book Now</button>
        </div>
        <div className="card">
          <h3 className="card-title">Product Owner Training</h3>
          <img
            src={S2img3}
            alt="Scrum Master Training"
            className="card-image"
          />
          <p className="card-info">
            Product Owners play a vital role in a scrum team - bridging business
            strategy and product execution, helping the Scrum Team create
            valuable products. This course is ideal for those seeking
            well-paying careers that involv...
            <a href="/ProductOwner" className="card-link">
              Find out more
            </a>
          </p>
          <p className="card-discount">
            Use code <strong> AGILE50</strong> for 50% off
          </p>
          <button className="card-button">Book Now</button>
        </div>
        <div className="card">
          <h3 className="card-title">Project Manager Training</h3>
          <img
            src={S2img4}
            alt="Scrum Master Training"
            className="card-image"
          />
          <p className="card-info">
            Project execution, control, and closure; project planning; risk
            management; stakeholder management; and team dynamics are just a few
            of the subjects covered in this course. Along with introducing
            various project manageme...
            <a href="/projectManager" className="card-link">
              Find out more
            </a>
          </p>
          <p className="card-discount">
            Use code <strong> AGILE50</strong> for 50% off
          </p>
          <button className="card-button">Book Now</button>
        </div>
      </div>
      {/* Section 3 */}
      <div className="section-with-gradient">
        <div className="row">
          <div className="section">
            <img
              src={S3img1}
              alt="Earn Up to £800 Per Day"
              className="section-img"
            />
            <h3 className="section-title">Earn Up to £800 Per Day</h3>
            <p className="section-info">
              Learn the fundamentals of Scrum & Agile Methodologies with our
              interactive, hands-on workshops.
            </p>
          </div>
          <div className="section">
            <img
              src={S3img2}
              alt="Virtual & Classroom Based Training"
              className="section-img"
            />
            <h3 className="section-title">
              Virtual & Classroom Based Training
            </h3>
            <p className="section-info">
              You can join us in person to learn, or virtually. We provide the
              options of both classroom and online training - meaning you can
              join us from anywhere in the world.
            </p>
          </div>
          <div className="section">
            <img src={S3img3} alt="Another Section" className="section-img" />
            <h3 className="section-title">Support & Mentorship</h3>
            <p className="section-info">
              You have the option to be get assigned a qualified mentor that
              will provide continuous support before, during and after your new
              career. So you’re never on your own
            </p>
          </div>
        </div>
        <div className="row">
          <div className="section">
            <img src={S3img4} alt="Different Section" className="section-img" />
            <h3 className="section-title">Exam Prep & CV Support</h3>
            <p className="section-info">
              Our trainers will go over mock tests and prep you for upcoming
              exams to get your qualification. We also guide you on writing your
              CV to help you with job applications.
            </p>
          </div>
          <div className="section">
            <img
              src={S3img5}
              alt="Another Different Section"
              className="section-img"
            />
            <h3 className="section-title">
              Gain an internationally Recognised certification{" "}
            </h3>
            <p className="section-info">
              Training is delivered by qualified Scrum Masters with many years
              of experience. We provide you with the knowledge to achieve an
              internationally recognised certification to help you accomplish
              your dream role.
            </p>
          </div>
          <div className="section">
            <img src={S3img6} alt="Final Section" className="section-img" />
            <h3 className="section-title">
              Practical hands on work experience provided
            </h3>
            <p className="section-info">
              As part of your training you will be assigned to a real live
              project where you will experience what it is like to be a part of
              a team and implement all that you’re learning.
            </p>
          </div>
        </div>
      </div>

      {/* Section 4 */}
      <div className="testimonials">
        <h2 className="testimonials-title">
          Press play to listen to some of our past trainees
        </h2>
        <div className="testimonial-container">
          <div className="testimonial-card">
            <img src={S4img1} alt="Sarah" className="testimonial-image" />
            <p className="testimonial-quote">"The hard work paid off"</p>
            <p className="testimonial-name">- Sarah</p>
            <audio controls>
              <source src={S4audio1} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </div>

          <div className="testimonial-card">
            <img src={S4img2} alt="John" className="testimonial-image" />
            <p className="testimonial-quote">"Lokem changed my life"</p>
            <p className="testimonial-name">- Danielle</p>
            <audio controls>
              <source src={S4audio2} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </div>

          <div className="testimonial-card">
            <img src={S4img3} alt="Emily" className="testimonial-image" />
            <p className="testimonial-quote">"I got multiple job offers"</p>
            <p className="testimonial-name">- Jack</p>
            <audio controls>
              <source src={S4audio3} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </div>

      {/* Section 5 */}
      <div className="carousel-section">
        <div className="carousel-content">
          <div className="carousel-page">
            <div className="carousel-left">
              <img
                src={pages[currentPage].profileImage}
                alt="Profile"
                className="profile-image"
              />
            </div>

            {/* Right Column with Quote and Quote Images */}
            <div className="carousel-right">
              <img src={S5openQuote} alt="Open Quote" className="open-quote" />
              {pages[currentPage].quote.slice(0, 5).map((text, index) => (
                <p key={index} className="quote-text">
                  {text}
                </p>
              ))}
              <p className="gold">
                {currentPage === 0 ? pages[0].quote[5] : pages[1].quote[5]}
              </p>
              <img
                src={S5closeQuote}
                alt="Close Quote"
                className="close-quote"
              />
            </div>
          </div>
        </div>

        {/* Right Arrow */}
        {currentPage < pages.length - 1 && (
          <img
            src={S5rightarrow}
            alt="Next"
            className="carousel-arrow-right"
            onClick={nextPage}
          />
        )}

        {/* Left Arrow  */}
        {currentPage > 0 && (
          <img
            src={S5leftarrow}
            alt="Previous"
            className="carousel-arrow-left"
            onClick={prevPage}
          />
        )}

        {/* Indicators */}
        <div className="carousel-indicators">
          {pages.map((_, index) => (
            <div
              key={index}
              className={`carousel-indicator ${
                currentPage === index ? "active" : ""
              }`}
              onClick={() => setCurrentPage(index)}
            ></div>
          ))}
        </div>
      </div>

      {/* Section 6 */}
      <div className="marquee-container-homepage">
        <div className="heading">
          Professionals trained by Lokem work at these companies
        </div>
        <div className="marquee-wrapper">
          <div className="marquee-homepage">
            {logos.map((logo) => (
              <div className="logo" key={logo.id}>
                <img src={logo.src} className="logo-img" alt={logo.alt} />
              </div>
            ))}
            {/* Repeat logos for infinite scroll */}
            {logos.map((logo) => (
              <div className="logo" key={`clone-${logo.id}`}>
                <img src={logo.src} className="logo-img" alt={logo.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section 7 */}
      <div className="get-in-touch">
        <h1>Get In Touch</h1>
        <p>Contact us today to find out how we can help</p>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>

          <div className="form-group">
            <input
              type="tel"
              name="contactNumber"
              placeholder="Contact Number"
              value={formData.contactNumber}
              onChange={handleInputChange}
            />
            {errors.contactNumber && (
              <span className="error-message">{errors.contactNumber}</span>
            )}
          </div>

          <div className="form-group">
            <select
              name="interest"
              value={formData.interest}
              onChange={handleInputChange}
            >
              <option value="">Please select your interest</option>
              <option value="SM Training">SM Training</option>
              <option value="BA Training">BA Training</option>
              <option value="PO Training">PO Training</option>
            </select>
            {errors.interest && (
              <span className="error-message">{errors.interest}</span>
            )}
          </div>

          <button type="submit">Send</button>
        </form>

        <div className="contact-details">
          <div className="contact">
            <img src={S7img1} className="icon" alt="" />
            <p>+44(0)2034881904</p>
          </div>
          <div className="contact">
            <img src={S7img2} className="icon" alt="" />
            <p>info@lokem.co.uk</p>
          </div>
        </div>
      </div>

      {/* Section 8 */}
      <div className="impact-section">
        <div className="impact-content">
          <p>
            When you make a purchase with us, you will be helping to create a
            <span className="highlight">&nbsp; positive &nbsp; </span>
            change by &nbsp;
            <span className="highlight"> improving &nbsp; </span>sanitation
            <span className="highlight">
              &nbsp; & preventing illnesses &nbsp;{" "}
            </span>
            in the lives of many.
          </p>
          <p className="second-p">
            A percentage of each purchase made goes towards
            <span className="highlight"> &nbsp; providing &nbsp; </span>
            families across the globe with access to clean water.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
