import React from "react";
import "../assets/scss/aboutus.scss";
import "../assets/scss/aboutus-impactSection.scss";
import "../assets/scss/aboutus-founder.scss";
import "../assets/scss/aboutus-ourvision.scss";
import "../assets/scss/aboutusJourney.scss";
import img from "../assets/images/our3Cs.png";
import img2 from "../assets/images/our3Cs.png";
import image1 from "../assets/images/lola1.jpg";
import image2 from "../assets/images/kemi.jpg";
import vision from "../assets/images/vision.jpeg";
import mission from "../assets/images/aboutusS1.jpg";
import map from "../assets/images/map2.png";

const AboutUsPage = () => {
  return (
    <div>
      <div className="about-us">
        <div className="about-us-banner">
          <div className="overlay">
            <h1>About Us</h1>
          </div>
        </div>
      </div>

      {/* Our Vision Our Mission Section 2 */}
      <div className="vision-mission-container">
        <div className="vision-section">
          <div className="vision-content">
            <h2>Our Vision</h2>
            <p>
              Immersing individuals and organisations into the world of
              transformational growth by delivering unrivalled bespoke training
              programmes that will foster a culture of continuous learning and
              agility.
            </p>
          </div>
          <div className="vision-image">
            <img src={vision} alt="Vision" />
          </div>
        </div>

        <div className="mission-section">
          <div className="mission-image">
            <img src={mission} alt="Mission" />
          </div>
          <div className="mission-content">
            <h2>Our Mission</h2>
            <p>
              Our aim is to empower and guide trainees to achieve their highest
              potential whilst providing an innovative learning experience. We
              are committed to delivering world-class training programmes that
              elevate organisational and individual agility, enabling them to
              succeed in a fast-changing world.
            </p>
          </div>
        </div>
      </div>
      {/* Our 3C's * */}

      <div className="section-3cs">
        <div className="section-3cs__header">
          <h2>Our 3 C's</h2>
          <hr className="section-3cs__underline" />
        </div>
        <div className="section-3cs__content">
          <div className="section-3cs__image">
            <img src={img} alt="3 C's Circular Graphic" />
          </div>
          <div className="section-3cs__details">
            <p className="section-3cs__intro">
              We value the importance of Agile and Scrum frameworks and how much
              positive impact they can have on individuals and organizations.
              Our community of trainers and Agile coaches are passionate and
              proud of Lokem’s culture that focuses on our 3 C’s.
            </p>
            <div className="section-3cs__points">
              <div className="point">
                <h3>Customer Centricity</h3>
                <p>Embracing customer feedback and creating what they need.</p>
              </div>
              <div className="point">
                <h3>Commitment</h3>
                <p>
                  Committed to ensuring success in understanding and
                  implementing agile in your business.
                </p>
              </div>
              <div className="point">
                <h3>Continuous Learning</h3>
                <p>
                  Encouraging continuous improvement through access to the
                  platform and collaboration with other trainees to build
                  long-lasting relationships.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 4 * */}
      <div className="container">
        <div className="textSection">
          <h2 className="title">Our Founders</h2>
          <p className="introText">
            Hello! We’re Lola and Kemi, the Founders of Lokem.
          </p>
          <p className="introText">
            We both had a lightbulb moment in 2019 after we discovered the
            abundance of opportunities in the tech space. Our careers up until
            then took us both all over the place, in various roles and across
            continents, but none of those positions left us feeling fulfilled.
            After self-reflecting, we changed careers and entered into project
            management.
          </p>
          <p className="introText">
            Upon embarking on our individual journeys, it became increasingly
            apparent that there was a lack of awareness about the various roles
            available and the different routes from which people from non-tech
            backgrounds could take to get into the tech space.
          </p>
          <p className="introText">
            We wanted to bring this knowledge to others and fill the gap with
            coaching and education within our communities.
          </p>
          <blockquote className="quote">
            <p className="highlight">“</p>
            <p className="quoteText">
              “I remember feeling like we'd walked into this reality that was a
              kept secret. The first thing we wanted to do was tell everybody
              about this. We wanted to share how much opportunity and space
              there was here for growth. I remember thinking, ‘How have we not
              known about this big secret? So the first thing we wanted to do
              was provide a bridge and increase awareness about this space.” -
              Kemi
            </p>
          </blockquote>
          <p className="conclusionText">
            Our driving passion is our desire to build a bridge between
            individuals and in-demand tech positions like Scrum Masters, Product
            Owners, and Business Analysts. We want to give others who come from
            similar backgrounds the inspiration and easy-to-follow strategy they
            need to fully transform their lives which is why we are using our
            experience to build programs, spaces for collaboration, and learning
            tracks that we wish we had when we made our career shifts. The
            opportunities are endless, providing great earning potential and
            great room for progression.
          </p>
        </div>
        <div className="imageSection">
          <img src={image1} alt="Founder Image 1" className="image" />
          <img src={image2} alt="Founder Image 2" className="image" />
        </div>
      </div>

      {/* Section 5 * */}
      <div className="journey-section">
        <div className="journey-content">
          <div className="journey-header">
            <h1>Lokem’s journey</h1>
            <hr />
            <p>
              Lokem has trained over 400 delegates from over six locations
              across the globe.{" "}
            </p>{" "}
            <p>
              {" "}
              All our training is delivered by Lokem’s team of highly qualified
              trainers who have a proven track record and real-life experiences
              in Agile frameworks.
            </p>
            <p>
              Our programme has been developed to provide exceptional training
              by using a hands-on approach which allows trainees to fully
              immerse themselves in the programme.
            </p>
            <p>
              Our team of highly qualified experts will continuously serve as
              mentors and continue to be a source of knowledge and guide
              throughout your journey with us.
            </p>
            <p>
              For bespoke training that fits around your business and staff get
              in touch with Lokem.
            </p>
            <h2>Our Trainees</h2>
          </div>
          <div className="journey-map">
            <img src={map} alt="World map showing training locations" />
          </div>
        </div>
        <div className="journey-info">
          <h2>Currently</h2>
          <ul>
            <li>Over 160 Trainees</li>
            <li>Over 400 Trained</li>
            <li>Six different time zones</li>
            <li>
              Countries including UK, North America, UAE, Somalia, Qatar,
              Nigeria, Canada, and more!
            </li>
            <li>Over half with PSMI, PSMII or PSPOI certification</li>
          </ul>
        </div>
      </div>

      {/* Section 6 * */}
      <div className="impact-section">
        <div className="impact-content">
          <p>
            When you make a purchase with us, you will be helping to create a
            <span className="highlight">&nbsp; positive &nbsp; </span>
            change by &nbsp;
            <span className="highlight"> improving &nbsp; </span> sanitation
            <span className="highlight">
              &nbsp; & preventing illnesses &nbsp;{" "}
            </span>
            in the lives of many.
          </p>
          <p>
            A percentage of each purchase made goes towards
            <span className="highlight"> &nbsp; providing &nbsp; </span>
            families across the globe with access to clean water.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
