import React from "react";
import "../assets/scss/footer.scss";
import { useLocation } from "react-router-dom"
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import logo from "../assets/images/lokemfooterlogo.png";
import { useState } from "react";
;

interface FormData {
  name: string;
  email: string;
  contactNumber: number | string;
}

interface FormErrors {
  name?: string;
  email?: string;
  contactNumber?: string;
}

const Footer = () => {
   const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    contactNumber: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // Validate form //
  const validate = (): FormErrors => {
    const newErrors: FormErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Please enter a valid name";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Please enter a valid email address";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!formData.contactNumber.toString().trim()) {
      newErrors.contactNumber = "Please enter a valid phone number";
    } else if (!/^\d+$/.test(formData.contactNumber.toString())) {
      newErrors.contactNumber = "Please enter a valid phone number";
    }

    return newErrors;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted successfully:", formData);
    }
  };

  const location = useLocation();

  const excludedRoutes = ["/adminLogin","/welcome","/trainees"];

  if (excludedRoutes.includes(location.pathname)) {
    return null; // Do not render the footer
  }

  return (
    <footer className="footer">
      <div className="footer-nav">
        <div className="footer-links">
          <a href="#trainings">Our Trainings</a>
          <a href="/aboutUs">About us</a>
          <a href="/blogs">Blogs</a>
          <a href="/testimonials">Testimonials</a>
          <a href="/faq">FAQ</a>
          <a href="/contactUs">Contact us</a>
          <a href="#login">Login</a>
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com/lokem/" target="_blank">
            {" "}
            <FaFacebookF />{" "}
          </a>
          <a href="https://www.instagram.com/lo.kem/" target="_blank">
            {" "}
            <FaInstagram />
          </a>
          <a href="https://x.com/lokemuk" target="_blank">
            {" "}
            <FaTwitter />
          </a>
        </div>
      </div>
      {/* FOOTER FORM  */}
      <div className="footer-form-wrapper">
        <div className="floatleft">
          <div className="footer-content">
            <form className="footer-form" onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.name}
                  </div>
                )}
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.email}
                  </div>
                )}
              </div>

              <div>
                <input
                  type="tel"
                  name="contactNumber"
                  placeholder="Contact Number"
                  value={formData.contactNumber}
                  onChange={handleChange}
                />
                {errors.contactNumber && (
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.contactNumber}
                  </div>
                )}
              </div>

              <button type="submit">Request a Call Back</button>
            </form>
          </div>
          <div className="footer-logo-content">
            <div className="footer-info">
              <img src={logo} alt="Lokem Logo" className="footer-logo" />

              <div className="footer-txt">
                <p className="fotter-p">
                  {" "}
                  Lokem are experts in Agile. We provide training and consulting
                  to organisations and individuals.{" "}
                </p>
                <p className="footer-p">
                  {" "}
                  Our consultants and training workshops are delivered by expert
                  Agile consultants experienced in working with various clients
                  globally.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="floatright">
          <div className="footer-contact">
            <p>
              Kemp House 160, City Road
              <br />
              London, EC1V 2NX
              <br />
              Phone: +44(0) 20 3488 1904
            </p>
          </div>
        </div>
      </div>
      <div className="copyright">Copyright © 2024</div>
    </footer>
  );
};

export default Footer;
