import React from "react";
import "../../assets/scss/blogs/sprintReview.scss";
import readBlog from "../../assets/images/blogs/readBlog.png";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

const SprintReview = () => {
  return (
    <div>
      {" "}
      {/* Banner Section */}
      <div className="blogSR-banner">
        <div className="overlay">
          <h1>Sprint Review</h1>
        </div>
      </div>
      {/* Blog Section */}
      <div className="blogSR-container">
        <div className="blogSR-wrapper">
          <div className="txtbold">
            The Sprint Retrospective is a Scrum event held at the end of each
            Sprint which evaluates what went well during the Sprint and
            identifies areas for improvement. It's inevitable that issues will
            arise during any Sprint, but how the team learns from these setbacks
            can make or break their progress.
          </div>
          <p>
            The goal of a retrospective is for a team to gain insight into their
            processes and to adapt their methods accordingly. It's important to
            note that retrospectives are not only for Agile teams - in fact,
            they can be used by any team that wants to improve their efficiency
            and performance. That being said, Agile teams tend to benefit the
            most from retrospectives since they're constantly changing and
            evolving. After all, one of the core tenets of Agile is to "inspect
            and adapt".
          </p>
          <p>
            Remember to be creative with what you discuss! This process isn't
            just about looking back at how things were done but also dreaming up
            ways you can improve going forward. It's very much an opportunity
            that encourages creativity within every individual during these
            meetings. No matter how effective your team's Sprint Retrospectives
            are, there’s always room for improvement. Here are five steps to
            make them even better.
          </p>
          <h3>Step 1. Engaging Icebreakers</h3>
          <p>
            Icebreakers are a great way to start a meeting. They can be as
            simple as a question to jog your memory, or a game to get team
            members excited. No matter what you choose, it plays the role of
            getting people relaxed, engaged and facilitates team bonding. It
            becomes a pathway to a more transparent and effective retro where
            the team can feel comfortable when sharing their thoughts on{" "}
            <strong> what went well, what can be improved</strong> and{" "}
            <strong> what to action.</strong> Next time, to get the meeting
            started, why not try playing a game of charades or asking each team
            member to think of the oldest object they have in their house and to
            tell a short story about it?
          </p>
          <h3>Step 2. Rewind, Recall, Roundup</h3>
          <p>
            Begin by asking your team what their goals are for the session in
            order to establish context. Once the stage has been set, encourage
            the team to offer opinions on what they did well, rather than
            focusing on specific areas for improvement. You can do this by going
            around the room and asking each person to share one thing they're
            proud of from the last Sprint, or even something they struggled
            with. This will help remove tension from the room and reinforce
            camaraderie. <br /> Don’t be afraid of laughter! It's crucial that
            during these meetings people can have fun working alongside one
            another even when it seems like the tasks are simple. It's important
            to let people express themselves openly and honestly. After all, the
            whole point of the retrospective is to identify issues so that they
            can be addressed. Some great techniques are as follows: The Mad,
            Sad, Glad Retrospective - this uses emotional responses as a prompt
            to help the team communicate any thoughts or feelings that came up
            in the past Sprint. It also boosts morale, improves emotional
            well-being and creates an environment where everyone can work
            together towards common goals. <br /> The Sailboat Retrospective -
            this is a fun way to get everyone thinking about their ideas for
            improvement. It's a way of visually identifying and representing
            what pushed the project forward, as well as what held it back. The
            KALM (Keep, Add, Less, and More) Retrospective - this format helps
            structure the meeting by focusing on how the team felt about
            specific ideas and processes in the Sprint, and adjusting how much
            they use them to help better the team in future increments. <br />{" "}
            Once the stage has been set and the responses have been gathered,
            it's time to begin brainstorming to discover insights.
          </p>
          <h3>Step 3. Generate Insights</h3>
          <p>
            Here is where the true benefits of retrospectives become apparent.
            The first step is to use the data from your chosen metric to help
            unearth insights that will enhance your team’s collaboration. For
            example, the happiness metric could be used to detect feelings of
            burnout within the team, whereas noticing a lack of congratulations
            in the Mad, Sad, Glad Retrospective could suggest team members are
            unaware of each others’ achievements.
          </p>
          <h3>Step 4. Ready, Solution, Action!</h3>
          <p>
            This is where the team comes up with potential solutions to the
            problems identified in the previous step. It's important to
            encourage everyone to come up with as many ideas as possible. The
            more options you have on the table, the better! After all, you want
            to find the best possible solution. <br /> You can use the ‘Start,
            Stop, and Continue’ technique to brainstorm actions and come up with
            a list of possible solutions. The team then votes on which ones they
            want to implement using the dot voting system. Keep things simple
            and focus on what will have the biggest impact.
          </p>
          <h3>Step 5. Reflect, Close, Celebrate!</h3>
          <p>
            The final step is to reflect on what was discussed and agreed upon
            during the retrospective. This is a good time to set goals for the
            next Sprint and to make sure that everyone is on the same page. It's
            also important to thank everyone for their participation and
            contributions. Remember, it’s a team effort! <br /> Retrospectives
            are a valuable tool for any Agile team and are a great way to make
            sure your team is on the same page and focused for the next Sprint.
            By using the techniques outlined in this article, you can get the
            most out of your retrospectives and help your team to continuously
            improve. <br /> Which technique will you try first?
          </p>

          {/* Blogs Foot  */}
          <div className="blog-foot">
            <div className="post">
              <img src={readBlog} alt="readblog" />
              <a href=""> &lt; Previous post</a>
              <p></p>
            </div>
            <div className="rate-post">
              <p>
                Rate this Article <br />
                <strong>0 from 0 ratings</strong>
              </p>

              <p>Share with your network</p>
              <div className="sociallinks">
                <a
                  href="https://www.facebook.com/lokem/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://twitter.com/lokemuk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://www.linkedin.com/lokemuk?_l=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </div>
            </div>
            <div className="post">
              {" "}
              <img src={readBlog} alt="readtheblog" />
              <a href=""> Next post &gt;</a>
              <p></p>
            </div>
          </div>

          {/* end  */}
        </div>
      </div>
    </div>
  );
};

export default SprintReview;
