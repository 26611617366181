import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/businessAnalyst.scss";
import img1 from "../assets/images/ba-training1.jpg";
import img2 from "../assets/images/ba-training2.jpg";
import img3 from "../assets/images/ba-training3.jpg";
import img4 from "../assets/images/sm-experts.png";
import img5 from "../assets/images/sm-mindset.png";
import img6 from "../assets/images/sm-agility.png";
import img7 from "../assets/images/sm-delivery.png";
import img8 from "../assets/images/po-learn.png";

//Section 5
import img9 from "../assets/images/ba-journey-map.png";

const businessAnalyst = () => {
  return (
    <div>
      {/* Banner */}
      <div className="business-analyst">
        <div className="business-analyst-banner">
          <div className="overlay">
            <h1>Become a Certified Business Analyst</h1>
            <Link to="/businessAnalystPD">
              {" "}
              <button className="book-now">Book now</button>
            </Link>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="training-overview-BA">
        <h2>Training Overview</h2>
        <div className="overview-content">
          <div className="text-content">
            <div className="text-column">
              <p>
                A Business Analyst is responsible for understanding the changes
                in business needs, as well as capturing, analysing and
                documenting requirements. They help to guide businesses in
                improving processes, products, services and software. They also
                support the communication and delivery of requirements to
                relevant stakeholders.
              </p>
              <p>
                Study with us and become a Business Analyst. Give your career a
                boost by embarking on a training course that employers around
                the world respect, value and, most importantly, need.
              </p>
            </div>
            <div className="text-column">
              <p>
                This training is for leaders, managers and other professionals
                seeking to gain in-depth knowledge on business analysis and
                learn how to improve their way of delivering projects.
              </p>
              <p>
                Looking for a change in career? New Management? No prior IT
                knowledge or background? No problem! We will give you all the
                skills & knowledge you need to succeed.
              </p>
              <p>We coach, mentor and guide you to become qualified.</p>
            </div>
          </div>
          <div className="images">
            <img src={img1} alt="Overview 1" />
            <img src={img2} alt="Overview 2" />
            <img src={img3} alt="Overview 3" />
          </div>
        </div>
      </div>

      {/* Section 3*/}
      <div className="training-objectives-BA">
        <h2>Training Objectives</h2>
        <h3>Students will learn how to:</h3>
        <div className="objectives-content">
          <div className="objective">
            <img src={img4} alt="Expert on Agile framework" />
            <p>Identify and evaluate options for improving businesses.</p>
          </div>
          <div className="objective">
            <img src={img5} alt="Adopt a agile mindset" />
            <p>
              Develop the skill set of engaging with stakeholders to understand
              and respond to their requirements in changing business
              environments.
            </p>
          </div>
          <div className="objective">
            <img src={img6} alt="Make a difference" />
            <p>
              Make a difference in the agility of your current or future
              employers’ transformation through the correct execution of the
              Business Analyst role.
            </p>
          </div>
          <div className="objective">
            <img src={img7} alt="Lead a team" />
            <p>
              Build the skills and knowledge required to support successful
              business change programmes.
            </p>
          </div>
        </div>
      </div>

      {/* Section 4 */}
      <div className="what-will-you-learn-BA">
        <div className="column-1">
          <h2>What Will You Learn?</h2>
          <p>
            Working on a project, and be able to apply theory to practice. You
            will constantly learn and develop the essential skills of a Business
            Analyst such as business acumen, communication, elicitation and how
            to specify and verify requirements.
          </p>
          <h3>Modules Covered</h3>
          <ul>
            <li className="underline">
              The roles and responsibilities of a Business Analyst
            </li>
            <li className="underline">
              How to identify and evaluate options for improving business
              processes
            </li>
            <li className="underline">
              Effective requirement gathering techniques, such as: interviews,
              workshops, prototyping and scenarios
            </li>
            <li className="underline">
              How to effectively identify, analyse and manage stakeholders
            </li>
            <li className="underline">
              Making a business case and assessing feasibility
            </li>
            <li className="underline">
              How to document, manage, validate and translate requirements
            </li>
            <li className="underline">
              Stakeholder management and collaboration techniques
            </li>
            <li className="underline">
              Identify and implement metrics to track value creation and
              successful product delivery
            </li>
          </ul>

          <h3 className="gold-title">Who Facilitates The Course</h3>
          <p>
            Our training workshops are delivered by expert Agile consultants
            experienced in working at renowned global companies including
            Barclaycard, Lloyds Bank, Selfridges & Vodafone.
          </p>
        </div>

        <div className="column-2-wrapper">
          <div className="column-2">
            <h3 className="training-title">Training Dates</h3>
            <p>Location: online</p>
            <p>
              Followed by a minimum of 3 months online training (Package also
              includes access to work experience opportunities)
            </p>
            <p className="discount-code">
            Use code <span className="code-bold">AGILE50</span> for 50% off
            </p>
            <button className="book-now">Book Now</button>
          </div>

          <div className="column-2-image">
            <img src={img8} alt="Final Image" />
          </div>
        </div>
      </div>

      {/* Section 5 */}
      <div className="final-image">
        <img src={img9} alt="Final Image" />
      </div>
    </div>
  );
};

export default businessAnalyst;
