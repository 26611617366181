import React from "react";
import "../../assets/scss/blogs/aceYour.scss";
import starimg from "../../assets/images/blogs/starimage.png";
import interviewjob from "../../assets/images/blogs/interview-job.png";
import exampleinterview from "../../assets/images/blogs/example-interview.png";
import readBlog from "../../assets/images/blogs/readBlog.png";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

const AceYour = () => {
  return (
    <div>
      {/* Banner Section */}
      <div className="blogAY-banner">
        <div className="overlay">
          <h1>How To Ace Your Interview With The Use Of The STAR Technique</h1>
        </div>
      </div>

      {/* Blog Section */}
      <div className="AY-container">
        <div className="AY-wrapper">
          <div className="AY-boldtxt">
            <p>
              Interviews can be daunting, stressful and unpredictable. For these
              reasons alone, it is important to minimise the effect these could
              have on you by preparing in advance. As with many things in life,
              "Preparation is Key" and an interview is by no means an exception.
            </p>
            <p>
              One of the ways to prepare in advance is by using a technique
              known as the STAR technique (a.k.a. Format or Method).
            </p>
          </div>
          <div className="AY-star">
            <div className="AY-stardes">
              <p>
                <strong>S</strong>ituation - Start off with a brief description
                of the scenario in correlation with the question. Set the scene
                briefly and move on to the next step.
              </p>
              <p>
                <strong>T</strong>ask - Mention what you were responsible for in
                that situation. Try to focus on one task because this will help
                you to keep the rest of your response unified and in line with
                the narrative you are trying to create. Again, keep this brief.
              </p>
              <p>
                <strong>A</strong>ction - Elaborate on the steps you took to
                remedy that situation. This is where you want to spend more time
                by carefully describing what actions you took to resolve the
                situation by aligning it to the task stated earlier.
              </p>
              <p>
                <strong>R</strong>esult - Share the positive outcome of your
                actions (feel free to add some data and figures to buttress the
                success of the result).
              </p>
            </div>
            <img src={starimg} alt="" className="AY-starpic" />
          </div>
          <div className="AY-star2">
            <div className="AY-stardes2">
              <p>
                The STAR technique is a very important and helpful tool to use
                during an interview because it allows an interviewee to link
                their previous work experience to the question that is being
                asked in such a way that it tells a story of how well they have
                handled a similar issue on the job.
              </p>
              <p>
                It also gives the interviewer an understanding of how much
                practical knowledge the interviewee has. The real value here is
                to show real, demonstrable and in-depth experience by adequately
                answering the questions that are being asked.
              </p>
              <p>
                This approach is fail-safe because it will help you to create a
                well-constructed response to your interviewer's question. This
                format is typically used to answer behavioural questions,
                scenario-based questions and questions that require you to tell
                a short but convincing story. As an interviewee, you want to
                create a clear picture of this story in your mind and depict it
                accordingly to your interviewer, but bear this in mind; your
                response must be concise and relevant.
              </p>
            </div>
            <img src={interviewjob} alt="" className="AY-starpic2" />
          </div>
          <h3>Example</h3>
          <div className="AY-boldtxt">
            <p>
              Now, let's attempt to answer a Scrum Master interview question
              using the STAR technique.
            </p>
          </div>
          <div className="Ay-interview">
            <img src={exampleinterview} alt="" />
            <div className="AY-interviewtxt">
              <p>
                Interviewer: "Can you tell me about a time you resolved a
                conflict in your team?"
              </p>
              <p>You: "Absolutely!"</p>
              <p>
                Situation - Remember to keep this simple. Focus on one situation
                by translating your thoughts clearly into words.
              </p>
              <p>
                "I recall a time in my previous role when conflict arose during
                a Sprint Planning session when the Product Owner took it upon
                themself to assign tasks to the developers in the team"
              </p>
              <p>
                Task - Now, shine a spotlight on the role you played by
                highlighting it.
              </p>
            </div>
          </div>
          <div className="interviewtxt">
            <p>
              "As the conflict unfolded right there, I knew I had to take
              immediate action as the Scrum Master to calm the situation down
              and resolve this."
            </p>
            <p>
              Action - Go into more details about how you carried out the task.
            </p>
            <p>
              "I suggested to the team that a session where the whole team
              revisits the Team Working Agreement might be a great idea which
              they all agreed to. I then went on to schedule this session for
              the whole team to attend."
            </p>
            <p>Result - Summarise the outcome of the actions you took.</p>
            <p>
              "This helped the whole team to understand their accountabilities
              more clearly which then increased team performance due to my swift
              action to nip the issue of micromanagement in the bud. After 'X'
              Sprints, the developers were able to increase their performance by
              'Y%'."
            </p>
          </div>
          <h3>Tips</h3>
          <p>
            Finally, I'll leave you with some VITAL things you should keep in
            mind when preparing to answer your interview questions. Surprise! It
            involves the use of STAR, again.
          </p>
          <p>
            1. Another STAR acronym (not to be confused with the actual STAR
            technique) serves as a reminder to keep your response to the
            interview questions:
          </p>
          <div className="star">
            <p>
              <strong>S</strong> - Streamlined <br />
              <strong>T</strong> - Time-boxed <br />
              <strong>A</strong> - Articulate <br />
              <strong>R</strong> - Relevant
            </p>
          </div>
          <p>The reasons for the list above are that</p>
          <ul>
            <li>A streamlined response is a great response.</li>
            <li>
              Maintaining a time-box helps to stay on track by preventing you
              from losing the attention of your interviewer.
            </li>
            <li>
              Being able to articulate your answer shows professionalism,
              confidence and in-depth experience.
            </li>
            <li>
              Keeping your response relevant to the interviewer's question is
              the ultimate goal.
            </li>
          </ul>
          <p>
            2. Practise your interview scenarios in advance until you know it
            like the back of your hand.
          </p>
          <p>
            3. If it is a video interview, make use of sticky notes by jotting
            down important words or phrases, again, using the STAR approach.
          </p>

          {/* Blogs Foot  */}
          <div className="blog-foot">
            <div className="post">
              <img src={readBlog} alt="readblog" />
              <a href=""> &lt; Previous post</a>
              <p></p>
            </div>
            <div className="rate-post">
              <p>
                Rate this Article <br />
                <strong>0 from 0 ratings</strong>
              </p>

              <p>Share with your network</p>
              <div className="sociallinks">
                <a
                  href="https://www.facebook.com/lokem/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://twitter.com/lokemuk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://www.linkedin.com/lokemuk?_l=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </div>
            </div>
            <div className="post">
              {" "}
              <img src={readBlog} alt="readtheblog" />
              <a href=""> Next post &gt;</a>
              <p></p>
            </div>
          </div>

          {/* end  */}
        </div>
      </div>
    </div>
  );
};

export default AceYour;
