import React from "react";
import { useState } from "react";
import "../assets/scss/viewProgrammes.scss";

const ViewProgrammes = () => {
  const [formValues, setFormValues] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    jobRole: "Please select",
    companySize: "Please select",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    jobRole: "",
    companySize: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let tempErrors = {
      fullName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      jobRole: "",
      companySize: "",
    };
    let isValid = true;

    if (!formValues.fullName) {
      tempErrors.fullName = "Full Name is required";
      isValid = false;
    }
    if (!formValues.companyName) {
      tempErrors.companyName = "Company Name is required";
      isValid = false;
    }
    if (!formValues.email) {
      tempErrors.email = "Email Address is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      tempErrors.email = "Email Address is invalid";
      isValid = false;
    }
    if (!formValues.phoneNumber) {
      tempErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    } else if (!/^\d+$/.test(formValues.phoneNumber)) {
      tempErrors.phoneNumber = "Phone Number is invalid";
      isValid = false;
    }
    if (formValues.jobRole === "Please select") {
      tempErrors.jobRole = "Please select a Job Role";
      isValid = false;
    }
    if (formValues.companySize === "Please select") {
      tempErrors.companySize = "Please select Company Size";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      // Submit the form
      console.log("Form submitted successfully", formValues);
    } else {
      console.log("Validation failed");
    }
  };
  return (
    <div>
      {/* Banner Section */}
      <div className="corporateT">
        <div className="viewProgrammes-banner">
          <div className="overlay">
            <h1>
              Empower your employees with the knowledge & skills of the future
            </h1>
            <div className="button-section">
              <div className="getintouch-btn">
                <a href="#book-a-call">Get In Touch</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="viewProgrammes__container">
        <div className="viewProgrammes__header">
          <span className="foundation">Foundation</span>
        </div>
        <div className="viewProgrammes__wrapper border__foundation">
          {/* Foundation  */}
          <div className="viewProgrammes-card height__foundation">
            <h3>INTRODUCTION TO AGILE</h3>
            <p>
              Trains participants on the Agile values, principles & practises.
              Includes various agile methodologies; such as Scrum & Kanban.
              Participants also learn key concepts such as prioritisation
              techniques; agile ways of working etc
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Anyone involved in projects; e.g: Project Managers,Programme
              Managers, Product teams, Quality Managers, Developers, Business
              Analysts, Testers, IT Managers etc
            </p>
          </div>
          <div className="viewProgrammes-card height__foundation">
            <h3>INTRODUCTION TO SCRUM</h3>
            <p>
              An introduction to the Scrum Framework. Participants learn an
              overview of the scrum framework; and the key roles,
              responsibilities & meetings involved.
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Anyone involved in Agile projects; e.g: Project Managers,Programme
              Managers, Product teams, Quality Managers, Developers, Business
              Analysts, Testers, IT Managers etc
            </p>
          </div>
          <div className="viewProgrammes-card height__foundation">
            <h3>PSYCHOLOGICAL SAFETY AT WORK</h3>
            <p>
              Psychological safety training is about creating an environment
              where people feel comfortable being authentic and honest- a key
              element for successful agile adoption within any organisation.
            </p>
            <p>
              This course will provide participants with practical steps to
              foster a culture of psychological safety within teams and
              organisations.
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Everyone working with teams.
            </p>
          </div>
        </div>

        <div className="viewProgrammes__header">
          <span className="intermediate">Intermediate</span>
        </div>
        <div className="viewProgrammes__wrapper border__intermediate">
          {/* Intermediate */}
          <div className="viewProgrammes-card height__intermediate">
            <h3>SCRUM MASTER</h3>
            <p>
              The Scrum Master is a key component of the scrum team. Through the
              Scrum Master's skill of clearing impediments they facilitate the
              team to deliver products of high quality.
            </p>
            <p>
              In this training participants gain a solid understanding of scrum
              and obtain a thorough understanding of the role of a Scrum Master.
              Learners deep dive into the underlying principles of scrum in
              addition to learning the art of facilitating the different scrum
              ceremonies.
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Anyone involved in projects; e.g: Project Managers,Programme
              Managers, Product teams, Quality Managers, Developers, Business
              Analysts, Testers, IT Directors & Managers etc
            </p>
          </div>
          <div className="viewProgrammes-card height__intermediate">
            <h3>PRODUCT OWNER</h3>
            <p>
              The Product Owner plays a vital role of bridging business strategy
              and product execution.
            </p>
            <p>
              Participants will learn product ownership within Scrum and how to
              maximize the value of products and return on investments. Product
              Owners enable Scrum Teams to deliver a high-quality product.
              Learners will learn how to set the team's priorities and the scope
              for the product.
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Anyone involved in Agile projects; e.g: Project Managers,Programme
              Managers, Product teams, Quality Managers, Developers, Business
              Analysts, Testers, IT Directors & Managers etc
            </p>
          </div>
          <div className="viewProgrammes-card height__intermediate">
            <h3>AGILE FOR REMOTE TEAMS</h3>
            <p>
              With businesses increasingly moving to a remote model for their
              ways of working, it is important to understand practical ways of
              working in this manner in order to maintain productivity,
              communication & motivation
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Anyone involved in Agile projects; e.g: Project Managers,Programme
              Managers, Product teams, Quality Managers, Developers, Business
              Analysts, Testers, IT Directors & Managers etc
            </p>
          </div>
        </div>

        <div className="viewProgrammes__header">
          <span className="advanced">Advanced</span>
        </div>
        <div className="viewProgrammes__wrapper border__advanced">
          {/* Advanced */}
          <div className="viewProgrammes-card height__advanced">
            <h3>AGILE TOOLS FOR BEGINNERS</h3>
            <p>
              Tools training for various Agile Tools for teams. Including
              standard work management tools such as: Jira, Trello, Planner. As
              well as collaborative tools such as: Miro, Mural etc
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Anyone involved in Agile projects; e.g: Project Managers,Programme
              Managers, Product teams, Quality Managers, Developers, Business
              Analysts, Testers, IT Directors & Managers, Scrum Master
            </p>
          </div>
          <div className="viewProgrammes-card height__advanced">
            <h3>SAFe for Teams</h3>
            <p>
              SAFe for Teams will teach you all about your role on an Agile team
              and how your team works with others as part of an ART.
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Anyone involved in Agile projects; e.g: Project Managers,Programme
              Managers, Product teams, Quality Managers, Developers, Business
              Analysts, Testers, IT Directors & Managers, Scrum Master
            </p>
          </div>
          <div className="viewProgrammes-card height__advanced">
            <h3>SAFe Scrum Master</h3>
            <p>
              The SAFe® Scrum Master course equips attendees to coach Agile
              teams to deliver business value at scale. The course teaches you
              the tactical skills needed to be an effective Scrum Master in a
              SAFe organization.
            </p>
            <br />
            <p>
              <strong>WHO IS IT FOR?</strong> <br />
              Anyone involved in Agile projects; e.g: Project Managers,Programme
              Managers, Product teams, Quality Managers, Developers, Business
              Analysts, Testers, IT Directors & Managers, Scrum Master
            </p>
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div id="book-a-call" className="book-a-call">
        <div className="book-a-call-heading">Can't find the right course?</div>
        <div className="book-a-call-des">
          Don't worry, we'll create the perfect course for you. Enquire below
        </div>
        <h2 className="book-a-call__title">
          Book a Call
          <hr className="underline" />
        </h2>
        <form className="book-a-call__form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Full Name</label>
            <input
              type="text"
              name="fullName"
              placeholder="Your full name.."
              value={formValues.fullName}
              onChange={handleChange}
            />
            {errors.fullName && (
              <span className="error">{errors.fullName}</span>
            )}
          </div>
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              name="companyName"
              placeholder="Your company name.."
              value={formValues.companyName}
              onChange={handleChange}
            />
            {errors.companyName && (
              <span className="error">{errors.companyName}</span>
            )}
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Your work email address.."
              value={formValues.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Your phone no.."
              value={formValues.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && (
              <span className="error">{errors.phoneNumber}</span>
            )}
          </div>
          <div className="form-group">
            <label>Job Role</label>
            <select
              name="jobRole"
              value={formValues.jobRole}
              onChange={handleChange}
            >
              <option value="Please select">Please select</option>
              <option value="C-Suite">C-Suite</option>
              <option value="Business (Head of/Director)">
                Business (Head of/Director)
              </option>
              <option value="HR">HR</option>
              <option value="Other">Other</option>
            </select>
            {errors.jobRole && <span className="error">{errors.jobRole}</span>}
          </div>
          <div className="form-group">
            <label>Company Size</label>
            <select
              name="companySize"
              value={formValues.companySize}
              onChange={handleChange}
            >
              <option value="Please select">Please select</option>
              <option value="0-10">0-10</option>
              <option value="11-100">11-100</option>
              <option value="201-1000">201-1000</option>
              <option value="1000+">1000+</option>
            </select>
            {errors.companySize && (
              <span className="error">{errors.companySize}</span>
            )}
          </div>
          <div className="form-group">
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewProgrammes;
