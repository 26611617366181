import React, { useState } from "react";
import "../assets/scss/CourseConfirmation.scss";

const CourseConfirmation = () => {
  return (
    <>
      <div className="header-top-page">
        <h1 className="course-confirm-message">
          Thank you for completing your online payment.
          <br />
          An email confirmation will be sent to you shortly.
        </h1>
      </div>
    </>
  );
};

export default CourseConfirmation;
