import React, { useState } from "react";
import "../../assets/scss/Admin/welcome.scss";
import favicon from '../../assets/images/favicon.ico';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Welcome() {
const [isMenuOpen, setIsMenuOpen] = useState(true);

const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
};

return (
    <div className="admin-page">
    <div className="sidebar">
        <div className="logo-section">
        <img src={favicon} alt="Lokem Logo" className="logo-img" />
        <span className="logo-text">Lokem</span>
        </div>
        <ul className="sidebar-links">
        <li><i className="fa-solid fa-users"></i> Trainees</li>
        <li><i className="fa-solid fa-calendar"></i> Add training dates</li>
        <li><i className="fa-solid fa-info-circle"></i> Show training dates</li>
        <li><i className="fa-solid fa-gift"></i> Add coupon</li>
        <li><i className="fa-solid fa-gift"></i> Show coupon</li>
        <li><i className="fa-solid fa-gift"></i> Show contact details</li>
        <li><i className="fa-solid fa-gift"></i> Show comments</li>
        <li><i className="fa-solid fa-link"></i> Add signup link</li>
        </ul>
        <button className="logout-btn">
        <i className="fa-solid fa-sign-out-alt"></i> Log out
        </button>
    </div>

    {/* Welcome Section */}
    <div
        className={`welcome-section ${isMenuOpen ? 'slide-left' : ''}`}
    >
        <div className="welcome-content">
        <i className="fa-solid fa-bars" onClick={toggleMenu}></i>
        <span>Welcome to admin</span>
        </div>
    </div>
    </div>
);
}

export default Welcome;