import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { updateFormData, setErrors, submitContactForm } from '../slices/contactSlice';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import '../assets/scss/contactus.scss';

const ContactUs = () => {
  const { formData, errors, responseMessage, status } = useSelector(
    (state: RootState) => state.contact
  );
  const dispatch = useDispatch<AppDispatch>();

  const validateForm = (): boolean => {
    const newErrors: typeof errors = { name: '', email: '', contactNumber: '', course: '' };
    let isValid = true;

    if (!formData.name) {
      newErrors.name = 'Full Name is required';
      isValid = false;
    }
    if (!formData.email) {
      newErrors.email = 'Email Address is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email Address is invalid';
      isValid = false;
    }
    if (!formData.contactNumber) {
      newErrors.contactNumber = 'Phone Number is required';
      isValid = false;
    } else if (!/^\+?[1-9]\d{1,14}$/.test(formData.contactNumber)) {
      newErrors.contactNumber = 'Phone Number is invalid';
      isValid = false;
    }
    if (!formData.course) {
      newErrors.course = 'Please select a course';
      isValid = false;
    }

    dispatch(setErrors(newErrors));
    return isValid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    dispatch(updateFormData({ [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(submitContactForm(formData));
    }
  };

  return (
    <div>
      <div className="contact-us">
        <div className="contact-us-banner">
          <div className="overlay">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="form-section">
          <div className="container">
            <div className="text-content">
              <div className="contact-card">
                <h1>We’d love to hear from you!</h1>
                <p>
                  Want a change in career?<br />
                  Want to upskill your workers?<br />
                  Drop us a call for more information.<br />
                  We’re delighted to answer all your questions.
                </p>
                <div className="contact-info">
                  <div className="contact-item">
                    <FaPhoneAlt className="contact-icon" />
                    <a href="tel:+442034881904" className="contact-link">
                      +44(0)2034881904
                    </a>
                  </div>
                  <div className="contact-item">
                    <FaEnvelope className="contact-icon" />
                    <a href="mailto:info@lokem.co.uk" className="contact-link">
                      info@lokem.co.uk
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <h2>Drop us a message</h2>
                  <label htmlFor="full-name">Full Name</label>
                  <input
                    type="text"
                    id="full-name"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your work email address"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    name="contactNumber"
                    placeholder="Your Phone Number"
                    value={formData.contactNumber}
                    onChange={handleChange}
                  />
                  {errors.contactNumber && <p className="error">{errors.contactNumber}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="course">Course</label>
                  <select
                    id="course"
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Please Select your interest
                    </option>
                    <option value="sm">SM Training</option>
                    <option value="ba">BA Training</option>
                    <option value="po">PO Training</option>
                  </select>
                  {errors.course && <p className="error">{errors.course}</p>}
                </div>
               <button type="submit" disabled={status === 'loading'}>
                {status === 'loading' ? 'Submitting...' : 'Submit'}
              </button>
              {responseMessage && (
                  <div id="contactMsg" className="contactMsg">
                    {responseMessage}
                  </div>
                )}
            </form>
            
            </div>
			
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
