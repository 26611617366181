import React, { useState, useEffect } from "react";
import "../assets/scss/individualtraining.scss";
import video from "../assets/videos/LokemFinal01.mp4";

// Images for carousel
import image1 from "../assets/images/Earn800.png";
import image2 from "../assets/images/virtualtraining.png";
import image3 from "../assets/images/support.png";
import image4 from "../assets/images/exam.png";
import image5 from "../assets/images/certification.png";
import image6 from "../assets/images/experience.png";


const IndividualTraining = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3;

  const sections = [
    {
      title: "Earn Up To £800 Per Day",
      text: "Learn the fundamentals of Scrum & Agile Methodologies and step into a higher earning potential through career opportunities as a Scrum Master, Business Analyst or Product Owner.",
      image: image1,
    },
    {
      title: "Virtual Training",
      text: "Join our international virtual training platform from wherever you are in the world, with easy access to professional courses that provide quality and assurance.",
      image: image2,
    },
    {
      title: "Support & Mentorship",
      text: "You have the option to be assigned a qualified mentor that will provide continuous support before, during and after your new career. So you’re never on your own.",
      image: image3,
    },
    {
      title: "Exam Prep & CV Support",
      text: "Our qualified trainers will prepare you for professional qualifications and exams, provide guidance on CV writing and support you through targeted job applications.",
      image: image4,
    },
    {
      title: "Gain An Internationally Recognised Certification",
      text: "Training is delivered by qualified and experienced Agile professionals, helping you to achieve your dream role by supporting you throughout your learning journey.",
      image: image5,
    },
    {
      title: "Practical Hands-on Work Experience Provided",
      text: "As part of your training you will be assigned to a real live project where you will experience what it is like to be a part of a team and implement all that you’re learning.",
      image: image6,
    },
    
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + itemsPerPage < sections.length ? prevIndex + itemsPerPage : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - itemsPerPage >= 0
        ? prevIndex - itemsPerPage
        : sections.length - itemsPerPage
    );
  };

  const currentSections = sections.slice(
    currentIndex,
    currentIndex + itemsPerPage
  );

  return (
    <div>
      {/* section 1 */}
      <div className="individual-training">
        <div className="individual-training-banner">
          <div className="overlay">
            <h1>FAST-TRACK YOUR JOURNEY TOWARDS BREAKING INTO TECH</h1>
            <p>
              You can successfully transition with our proven training
              programmes (without having to code)
            </p>
          </div>
        </div>
      </div>

      {/* section 2 */}
      <div className="video-section">
        <h2 className="video-title">
          Introduc<span className="underline-letters">tion</span> to Scrum
        </h2>

        {/* video */}
        <div className="video-container">
          <video controls>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* section 3 */}
      <div className="training-programs">
        <h2 className="programs-title">
          Training <span className="underline-prog">Prog</span>rammes
        </h2>
        <div className="cards-container">
          <div className="card">
            <h3 className="card-title">Scrum Master Training</h3>
            <p>
              The Scrum Master is the servant leader of the team. They ensure
              that the team lives agile values and principles while also
              following Scrum practices - by clearing impediments and blockers
              they enable the team to deliver products of high quality.
            </p>
            <p>
              This course kicks off with an intensive two-day training course
              encompassing Scrum fundamentals delivered by professionals with
              experience in the field. If you are looking to join one of the
              world's most highly demanded professions then this is the course
              for you
            </p>
            <a href="./scrumMaster">
              <button className="find-out-more">Find Out More</button>
            </a>
          </div>
          <div className="card">
            <h3 className="card-title">Business Analyst Training</h3>
            <p>
              Business Analysts (BA) support Product Owners with analysis,
              addressing customers’ needs and facilitating pressing dialogues.
              Our course teaches you how BA’s support the Scrum team by
              utilising the process of mpiricism and behaviour driven
              development to produce increments of high value.
            </p>
            <p>
              Key analytical techniques of business analysis are explored and
              applied within a Scrum environment, which will prepare you for a
              career as a successful, certified Business Analyst
            </p>
            <a href="./businessAnalyst">
              <button className="find-out-more">Find Out More</button>
            </a>
          </div>
          <div className="card">
            <h3 className="card-title">Product Owner Training</h3>
            <p>
              Product Owners play a vital role in a scrum team - bridging
              business strategy and product execution, helping the Scrum Team
              create valuable products. This course is ideal for those seeking
              well-paying careers that involve leading the development of new
              products.
            </p>
            <p>
              We help trainees to understand the critical role of the Product
              Owner and successful product delivery by offering extensive
              training, developing their skillset to a level of excellency
            </p>
            <a href="./productOwner">
              <button className="find-out-more">Find Out More</button>
            </a>
          </div>
          <div className="card">
            <h3 className="card-title">Project Management Training</h3>
            <p>
              The purpose of this Project Manager Training is to give
              participants the information and abilities needed to oversee
              large-scale projects effectively, from the planning and
              development stage all the way through to the deployment and
              completion stage.
            </p>
            <p>
              The purpose of this Project Manager Training is to give
              participants the information and abilities
            </p>
            <a href="/projectManager">
              <button className="find-out-more">Find Out More</button>
            </a>
          </div>
        </div>
      </div>

      {/* section 4 */}
      <div className="carousel-section-training">
        <h2 className="carousel-title-training">
          B<span className="underline-prog-training">enef</span>its
        </h2>
        <div className="carousel-container-training">
          <button className="carousel-button-training prev" onClick={handlePrev}>
            &lt;
          </button>
          <div className="carousel-content-training">
            {currentSections.map((section, index) => (
              <div key={index} className="carousel-card-training">
                <img src={section.image} alt={section.title} />
                <h3>{section.title}</h3>
                <p>{section.text}</p>
              </div>
            ))}
          </div>
          <button className="carousel-button-training next" onClick={handleNext}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default IndividualTraining;
