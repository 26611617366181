import React, { Component } from "react";
import {
  Form,
  Button,
  Image,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
//import { ILoginState } from '../actions/homePageAction';
//import { loginValidator } from '../actions/homePageAction';
import lockIcon from "../assets/images/auth/lock.png";
import mailIcon from "../assets/images/auth/mail.png";
//import { logReducer } from "../reducer/logReducer";
import { connect } from "react-redux";
//import { RootState } from '../store';
//import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
//import "../assets/scss/login.scss";

const Login = () => {
  return (
    <div className={"login-form"}>
      {/* <h2>Get More things done with login</h2> */}
      <h5 className="login-heading">
        <span>Login</span>
      </h5>
      <div className={"login-main-wrap"}></div>
      <Form>
        <Form.Group controlId="formBasicEmail">
          <InputGroup>
            <input
              type={"text"}
              name={"email"}
              //value={email}
              className={"form-control form-input"}
              placeholder="Email Address "
              //onChange={this.handleChange}
            />
            <Form.Label
              className={`login-form-label "top-label" `}
            ></Form.Label>
            <span className="login-icon">
              <Image src={mailIcon} className="my-custom-icon" />
            </span>
          </InputGroup>
          <div className={"text-danger error-field"}></div>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <InputGroup>
            <input
              type={"password"}
              name={"password"}
              //value={password}
              className={"form-control form-input"}
              placeholder="Password "
              //onChange={this.handleChange}
            />
            <Form.Label className={`login-form-label`}></Form.Label>
            <span className="login-icon">
              <Image src={lockIcon} className="my-custom-icon" />
            </span>
            {/* { (
                 (
                  <Alert
                    style={{ width: "25rem"}}
                    variant="danger"
                  >     
                  { (
                    <div>
                    <div>
                      Your account is locked now. <br></br>
                      <span
                        onClick={this.props.resetShow}
                        className="forget-link"
                      >
                        Click here
                      </span> to reset your password.
                    </div>
                    </div>
                  ) : (
                    <div>
                    <div>Your email or password is incorrect</div>
                    <div>
                      You have no more attempts, your account is locked now. <br></br>
                      <span
                        onClick={this.props.resetShow}
                        className="forget-link"
                      >
                        Click here
                      </span> to reset your password.
                    </div>
                    </div>
                  ))}               
                    
                    
                  </Alert>
                ) : ( 
                  <Alert
                    style={{ width: "24rem"}}
                    variant="danger"
                  >
                   Your email or password is incorrect
                  </Alert>
                )
              ) : (
                ""
                )}*/}

            {/* <Alert variant="danger" style={{ width: "24rem", height:"1rem" }}>
              <Alert.Heading style={{ width: "14rem", height:"1rem" }}>
                This is a danger alert which has red background
              </Alert.Heading>
            </Alert> */}
            {/* <OverlayTrigger
                key={'top'}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip`}>
                    Passwords are a minimum of 8 characters, and must contain an
                    uppercase letter, number, and special character
                  </Tooltip>
                }
              >
                <span className='login-info'>
                  <i className='fas fa-info' />
                </span>
              </OverlayTrigger> */}
          </InputGroup>

          <div className={"text-danger error-field"}></div>
        </Form.Group>
        {/* <ToggleButtonGroup type="radio" name="options" defaultValue={1} className="custom-toggle">
            <ToggleButton value={1} variant="link">Organization</ToggleButton>
            <ToggleButton value={2} variant="link">User</ToggleButton>
          </ToggleButtonGroup> */}
        <div className="clearfix" />
        <div className="text-center my-3">
          <Button type="submit" className="btn-submit login-btn">
            Login
          </Button>
        </div>

        <div className="remember-div">
          <Form.Check
            //custom
            type="checkbox"
            name={"rememberMe"}
            //checked={rememberMe}
            //onChange={this.handleChange}
            id="custom-checkbox"
            label="Remember me"
            className="float-left"
          />
          <span
            // onClick={this.props.forgotShow}
            className="forget-link float-right"
          >
            Forgot Password?
          </span>
        </div>
      </Form>
    </div>
  );
};

export default Login;
