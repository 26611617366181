import React from 'react';
import '../../assets/scss/blogs/userStory.scss';
import img1 from '../../assets/images/blogs/multiple.png';
import img2 from '../../assets/images/blogs/userStory.png';
import img3 from '../../assets/images/blogs/epic-banner.png';

import readBlog from "../../assets/images/blogs/readBlog.png";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

const UserStory = () => {
  return (
    <div className="blog-user-story">
      <div className="banner">
        <h1>How to go from an Epic to User Story</h1>
      </div>

      <section className="intro-section">
        <p className='intro'>If you have ever worked on a large project, 
          you’ll know how daunting it is to plan how to execute all the 
          small details to lead the project to success. In an Agile project, 
          work is broken down into more practical tasks, so that the 
          Scrum team can deliver work each Sprint. Large amounts of 
          work, known as Epics, are broken down into smaller tasks 
          or User Stories, which help the team understand what needs 
          to be done to meet the Sprint Goal.</p>
      </section>

      <section className="epic-section">
        <div className="text">
          <h2>What is an Epic?</h2>
          <p>An Epic is essentially the 'Big Idea' of the project. 
            For instance, one could consider spring cleaning an Epic, 
            since it's an event with multiple layers which cannot 
            all be completed simultaneously. That's why we break 
            it down into User Stories to make it manageable.</p>
        </div>
        <div className="image">
          <img src={img1} alt="Epic" />
        </div>
      </section>

      <section className="user-story-section">
        <div className="text">
          <h2>What is a User Story?</h2>
          <p>A User Story derives from an Epic and is a simplified 
            requirement that can be achieved in a Sprint. So, if the 
            Epic is spring cleaning, then some User Stories could be 
            to store winter clothes, wash all the linen and to arrange 
            the kitchen pantry. In a Scrum context, the User Stories 
            would relate to a specific functionality required for the 
            product.</p>
        </div>
      </section>

      <section className="conversion-section">
            <div className="text">
                <h2>How do we go from an Epic to a User Story?</h2>
                <p>
                The Product Owner plays a pivotal role in breaking 
                Epics down into User Stories. Once all the Epics 
                have been written down and the required features 
                presented, the Product Owner then determines which 
                functionalities are necessary for the features to work. 
                These become the User Stories. These User Stories will 
                then be added to the Backlog and those considered most 
                important by the Product Owner will be worked on and 
                delivered in the following Sprint. Hence, the Backlog 
                is a visual display of a prioritised list of Epics 
                and User Stories. Some Product Owners may choose not 
                to create User Stories for Epics that are not 
                considered a priority and will instead plan the 
                required features once necessary.
                </p>
            </div>
            <div className="image">
                <img className='epic-image' src={img2} alt="Epic to User Story" />
            </div>
        </section>

      <section className="decomposition-section">
        <div className="text">
          <h2>The Need for Decomposition</h2>
          <p>Breaking Epics down into User Stories helps Scrum Teams 
            understand what functionalities are required to be delivered 
            in greater detail at the end of each Sprint. With the clear 
            and prioritised backlog as guidance, team members can 
            work collaboratively and productively to ensure each 
            User Story is completed one by one in a clear and 
            organised system. So, the next time you have a big 
            project planned, think of how you can break down the 
            big components into more manageable ones to execute it 
            smoothly.</p>
        </div>
        <div className="image">
          <img src={img3} alt="Decomposition" />
        </div>
      </section>
       {/* Blog Footer */}
       <div className="blog-foot">
          <div className="post">
            <img src={readBlog} alt="readblog" />
            <a href=""> &lt; Previous post</a>
          </div>
          <div className="rate-post">
            <p>
              Rate this Article <br />
              <strong>0 from 0 ratings</strong>
            </p>
            <p>Share with your network</p>
            <div className="sociallinks">
              <a href="https://www.facebook.com/lokem/" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </a>
              <a href="https://twitter.com/lokemuk" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href="https://www.linkedin.com/lokemuk?_l=en_US" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div className="post">
            <img src={readBlog} alt="readtheblog" />
            <a href="">Next post &gt;</a>
          </div>
        </div>
    </div>
  );
};

export default UserStory;
