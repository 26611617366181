import React from "react";
import "../../assets/scss/blogs/agileCulture.scss";
import readBlog from "../../assets/images/blogs/readBlog.png";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

const AgileCulture = () => {
  return (
    <div>
      {/* Banner Section */}
      <div className="blogseven-banner">
        <div className="overlay">
          <h1>Does the Agile culture need more emotional intelligence?</h1>
        </div>
      </div>

      <div className="blogseven-container">
        <div className="blogseven-wrapper">
          <div className="blogseven-toptxt">
            With the pandemic in the rear mirror, companies are realising the
            importance of adapting and modifying their business structures in
            order to be ready to face constant and unpredictable challenges.
            This is one of many reasons why more organisations are adopting
            Agile practices. However, many companies focus on their processes
            being more Agile but often forget the importance of the people being
            Agile. Companies who work smarter do both, because an organisation
            needs Agile workers to be successful.
          </div>
          <p>
            According to a report by Wiley titled{" "}
            <a
              href="https://www.discprofile.com/CMS/media/doc/ed/agility-unlocked.pdf"
              target="_blank"
            >
              <i> “Cognitive vs Emotional intelligence“</i>
            </a>
            , the key to developing an Agile workforce is to be emotionally
            intelligent. The report argues that the Agile culture demands people
            who are resilient and proactive, while the Agile mindset requires
            individuals who are empathetic and keen listeners.
          </p>
          <br />
          <h3>The dangers of ignoring emotional intelligence</h3>
          <p>
            Wiley surveyed 2,500 professionals in 2020 and discovered some
            interesting points about emotional intelligence in the workplace.
          </p>
          <ul>
            <li>
              80% of those surveyed had worked on teams where low emotional
              intelligence hurt the general productivity
            </li>
            <li>
              More than 40% had actually quit a job after working with people
              with low emotional intelligence
            </li>
            <li>
              Managers spent more time resolving interpersonal issues than the
              work their employees do
            </li>
          </ul>
          <p>
            These findings show that if an organisation does not make a serious
            commitment to integrating emotional intelligence into their
            leadership and grant opportunities for training in this field, there
            will be serious repercussions.
          </p>
          <br />
          <h3>Cognitive vs emotional intelligence</h3>
          <p>
            When trying to be Agile, one can often confuse cognitive with
            emotional intelligence. We tend to encourage concepts such as
            self-development, willingness to learn and the expansion of
            knowledge, and the emphasis on these often means that emotional
            intelligence is overlooked.
          </p>
          <br />
          <p>
            Interactions between different people in the team can become
            strained and at times challenging because everyone is unique with
            their own preferences and quirks. An emotionally intelligent Scrum
            Master will be able to observe and mediate the situation, ensuring
            that all parties involved are heard without escalating the conflict.
            These scenarios cement the Scrum Master as the basis of a
            successful, resilient and adaptable team.
          </p>
          <br />
          <p>
            Now to answer the question:{" "}
            <i> Does the Agile culture need more emotional intelligence?</i> Yes
            it does! Wiley’s research confirms that more Agile workers are
            weighing the importance of emotional intelligence in their leaders
            and their peers and a significant number of managers now acknowledge
            the concept is needed for a cohesive team.
          </p>
          <br />
          <p>
            Agile teams that prioritise emotional intelligence can improve
            decision-making, strengthen connections and better understand and
            manage each other’s emotions, leading to a healthier and happier
            work environment.
          </p>

          {/* Blogs Foot  */}
          <div className="blog-foot">
            <div className="post">
              <img src={readBlog} alt="readblog" />
              <a href=""> &lt; Previous post</a>
              <p></p>
            </div>
            <div className="rate-post">
              <p>
                Rate this Article <br />
                <strong>0 from 0 ratings</strong>
              </p>

              <p>Share with your network</p>
              <div className="sociallinks">
                <a
                  href="https://www.facebook.com/lokem/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://twitter.com/lokemuk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://www.linkedin.com/lokemuk?_l=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </div>
            </div>
            <div className="post">
              {" "}
              <img src={readBlog} alt="readtheblog" />
              <a href=""> Next post &gt;</a>
              <p></p>
            </div>
          </div>

          {/* end  */}
        </div>
      </div>
    </div>
  );
};

export default AgileCulture;
