// DataComponent.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const DataComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_DATA_REQUEST" });
  }, [dispatch]);

  const { loading, data, error, color } = useSelector((state) => state.data);

  if (loading) return <div style={{ color }}>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1 style={{ color }}>Data</h1>
      <ul style={{ color }}>
        {data.map((item) => (
          <li key={item.id} style={{ color }}>
            <strong>{item.name}</strong> <br />
            <em>{item.email}</em> <br />
            <p>{item.body}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DataComponent;