import { useState, useEffect } from 'react';
import '../assets/scss/header.scss';
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import headerimg from '.././assets/images/logo-black.jpg'

const Marquee = () => {
  return (
    <div className="marquee-container">
      <div className="marquee-text-container">
        <span>FREE SCRUM MASTER TASTER SESSION</span>
      </div>
      <div className="button-container">
        <button className="sign-up-btn">SIGN UP</button>
      </div>
    </div>
  );
};

const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(true);
    const [isHomePage, setIsHomePage] = useState(false);
  
    const toggleNav = () => {
      setIsNavOpen(!isNavOpen);
    };

    useEffect(() => {
      const currentPath = window.location.pathname;
      if (currentPath === '/' || currentPath === '/homepage') {
        setIsHomePage(true);
      } else {
        setIsHomePage(false);
      }
    }, []);

    const location = useLocation();
      const excludedRoutes = ["/adminLogin","/welcome","/trainees"];

      if (excludedRoutes.includes(location.pathname)) {
        return null; // Do not render the header
      }
  
    return (
      <div>
      <div className="header">
        <div className="header-logo">
          <a href="./homePage" className="header-logo-link">
            <img className="header-logo-img" src={headerimg} alt="Logo" />
          </a>
          <div>
            <h1 className="header-logo-title">Agile Training Experts</h1>
          </div>
        </div>

        <button className="header-hamburger" onClick={toggleNav}>
        <FontAwesomeIcon icon={faBars} className="header-hamburger-icon" />
        </button>
  
        <nav className={`header-nav ${isNavOpen ? 'header-nav--open' : ''}`}>
          <ul className="header-nav-list">
            <li className="header-nav-item">
              <a href="#our-trainings" className="header-nav-link">Our Trainings <i className="fa fa-caret-down"></i> </a>
              <ul className="header-dropdown">
                <li><a href="individualTraining" className="header-dropdown-link">Individual Training</a></li>
                <li><a href="corporateTraining" className="header-dropdown-link">Corporate Training</a></li>
              </ul>
            </li>
            <li className="header-nav-item"><a href="./aboutUs" className="header-nav-link">About Us</a></li>
            <li className="header-nav-item"><a href="./blogs" className="header-nav-link">Blogs</a></li>
            <li className="header-nav-item"><a href="./testimonials" className="header-nav-link">Testimonials</a></li>
            <li className="header-nav-item"><a href="./faq" className="header-nav-link">FAQ</a></li>
            <li className="header-nav-item"><a href="./contactUs" className="header-nav-link">Contact Us</a></li>
            <li className="header-nav-item">
              <a href="#login" className="header-login-btn">Login</a>
            </li>
          </ul>
        </nav>
      </div>
      {isHomePage && <Marquee />}
      </div>
    );
  };
  
  export default Header;