import React, { useState } from "react";
import "../assets/scss/corporatetraining.scss";
import img1 from "../assets/images/training1.jpeg";
import img2 from "../assets/images/training2.jpeg";
import img3 from "../assets/images/training3.jpeg";
import img4 from "../assets/images/training4.jpeg";
import whyLokem from "../assets/images/whyLokem.jpg";

const CorporateTraining = () => {
  const [formValues, setFormValues] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    jobRole: "Please select",
    companySize: "Please select",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    jobRole: "",
    companySize: "",
  });
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const validate = () => {
    let tempErrors = {
      fullName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      jobRole: "",
      companySize: "",
    };
    let isValid = true;
    if (!formValues.fullName) {
      tempErrors.fullName = "Please enter valid Full name";
      isValid = false;
    }
    if (!formValues.companyName) {
      tempErrors.companyName = "Please enter valid Company name";
      isValid = false;
    }
    if (!formValues.email) {
      tempErrors.email = "Please enter valid Email address";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      tempErrors.email = "Please enter valid email address";
      isValid = false;
    }
    if (!formValues.phoneNumber) {
      tempErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    } else if (!/^\d+$/.test(formValues.phoneNumber)) {
      tempErrors.phoneNumber = "Phone Number is invalid";
      isValid = false;
    }
    if (formValues.jobRole === "Please select") {
      tempErrors.jobRole = "Please choose Job role";
      isValid = false;
    }
    if (formValues.companySize === "Please select") {
      tempErrors.companySize = "Please choose Company size";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      console.log("Form submitted successfully", formValues);
    } else {
      console.log("Validation failed");
    }
  };

  return (
    <div>
      {/* Banner Section */}
      <div className="corporateT">
        <div className="corporateT-banner">
          <div className="overlay">
            <h1>
              Upskill your Employees in Agile & Transform Your Organisation.
            </h1>
            <p className="banner-p">
              Upskill existing teams with Agile training programmes. Thrive in
              an increasing dynamic economy.
            </p>
            <div className="button-section">
              <div className="getintouch-btn">
                <a href="/">Get In Touch</a>
              </div>
              <div className="viewprogrammes-btn">
                <a href="/viewProgrammes">View Programmes</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="business-section">
        <div className="business-success">
          <h2 className="business-success__title">
            We help your business attain & sustain success in today’s fast-paced
            business world
          </h2>
          <div className="business-success__content">
            <div className="business-success__item">
              <img
                src={img1}
                alt="Bridge the Skills Gap"
                className="business-success__image"
              />
              <div className="business-success__text">
                <h3>Bridge the Skills Gap</h3>
                <p>
                  Upskill existing teams in Agile knowledge in order to meet
                  your business objectives & respond quickly to increasingly
                  changing markets.
                </p>
              </div>
            </div>
            <div className="business-success__item">
              <img
                src={img2}
                alt="Rapidly Respond to Change"
                className="business-success__image"
              />
              <div className="business-success__text">
                <h3>Rapidly Respond to Change</h3>
                <p>
                  Keep up with the constantly changing landscape of the market.
                </p>
                <p>Think COVID!</p>
              </div>
            </div>
            <div className="business-success__item">
              <img
                src={img3}
                alt="Enhance Company Culture"
                className="business-success__image"
              />
              <div className="business-success__text">
                <h3>Enhance Company Culture</h3>
                <p>
                  Build a culture that encourages innovation, continuous
                  learning and a focus on efficiently delivering value.
                </p>
              </div>
            </div>
            <div className="business-success__item">
              <img
                src={img4}
                alt="Increase Staff Retention"
                className="business-success__image"
              />
              <div className="business-success__text">
                <h3>Increase Staff Retention</h3>
                <p>
                  Boost employee satisfaction and improve retention by investing
                  in staff development.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div className="skills-section">
        <h2 className="skills-section__title">
          Embed the Skills Your Business Requires For Sustained Success
        </h2>
        <div className="skills-section__content">
          <div className="skills-section__card">
            <h3>CUSTOMER FOCUSED</h3>
            <p>Delight your customers with customer-centric solutions.</p>
          </div>
          <div className="skills-section__card">
            <h3>AGILE LEADERS</h3>
            <p>
              Develop the future skills required by leaders at all levels to
              effectively lead and manage teams.
            </p>
          </div>
          <div className="skills-section__card">
            <h3>OPTIMISED BUSINESS OPERATIONS</h3>
            <p>
              Increase time to market. Decrease waste. Optimise the flow of
              value to your customers.
            </p>
          </div>
        </div>
        <div className="skills-section__button">
          <a href="/viewProgrammes">View Programmes</a>
        </div>
      </div>

      {/* Section 4 */}
      <div className="whyLokem">
        <div className="whyLokem__container">
          <div className="whyLokem__title">
            <h2>Why Lokem?</h2>
            <hr />
            <p className="whyLokem__boldtxt">
              At Lokem, we take great pride in our mission to nurture
              transformational changes within your organisation by equipping
              your employees with the necessary skills and tools they need to
              thrive and survive in an ever-changing, highly competitive market.
            </p>
          </div>
          <div className="whyLokem__content">
            <div className="whyLokem__text">
              <div>
                Our passion fuels our proactive approach to product development,
                and it keeps Agility at the core of who we are. It keeps us on
                the path to support you to achieve success with every step that
                we take together.
              </div>
              <div>
                The driving force behind our many successes across many sectors
                over the years is our excellent team of Agile coaches, Training
                consultants and Personnel. Our workforce is made up of
                individuals with a wide range of expertise and skill sets,
                acquired from working with different clients around the world.
              </div>
              <div>
                Our devotion to helping our clients grow and sustain their
                businesses is the reason why our training program is unique for
                every client and their employees.
              </div>
            </div>
            <div className="whyLokem__image">
              <img src={whyLokem} alt="whyLokem" />
            </div>
          </div>
        </div>
      </div>

      {/* Section 5 */}
      <div className="book-a-call">
        <h2 className="book-a-call__title">
          Book a Call
          <hr className="underline" />
        </h2>
        <form className="book-a-call__form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Full name</label>
            <input
              type="text"
              name="fullName"
              placeholder="Your full name.."
              value={formValues.fullName}
              onChange={handleChange}
            />
            {errors.fullName && (
              <span className="error">{errors.fullName}</span>
            )}
          </div>
          <div className="form-group">
            <label>Company name</label>
            <input
              type="text"
              name="companyName"
              placeholder="Your company name.."
              value={formValues.companyName}
              onChange={handleChange}
            />
            {errors.companyName && (
              <span className="error">{errors.companyName}</span>
            )}
          </div>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              placeholder="Your work email address.."
              value={formValues.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-group">
            <label>Phone number</label>
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Your phone no.."
              value={formValues.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && (
              <span className="error">{errors.phoneNumber}</span>
            )}
          </div>
          <div className="form-group">
            <label>Job role</label>
            <select
              className="select"
              name="jobRole"
              value={formValues.jobRole}
              onChange={handleChange}
            >
              <option value="Please select">Please select</option>
              <option value="C-Suite">C-Suite</option>
              <option value="Business (Head of/Director)">
                Business (Head of/Director)
              </option>
              <option value="HR">HR</option>
              <option value="Other">Other</option>
            </select>
            {errors.jobRole && <span className="error">{errors.jobRole}</span>}
          </div>
          <div className="form-group">
            <label>Company size</label>
            <select
              className="select"
              name="companySize"
              value={formValues.companySize}
              onChange={handleChange}
            >
              <option value="Please select">Please select</option>
              <option value="0-10">0-10</option>
              <option value="11-100">11-100</option>
              <option value="201-1000">201-1000</option>
              <option value="1000+">1000+</option>
            </select>
            {errors.companySize && (
              <span className="error">{errors.companySize}</span>
            )}
          </div>
          <div className="form-group">
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CorporateTraining;
