import React from "react";
import "../assets/scss/paymentDetails.scss";
import { useState } from "react";

const PaymentDetails = () => {
  const [formData, setFormData] = useState({
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
    postalCode: "",
    country: "United Kingdom",
  });

  const [errors, setErrors] = useState({
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
    postalCode: "",
  });

  const [activePaymentMethod, setActivePaymentMethod] = useState("card");

  const validateField = (field: string, value: string) => {
    let errorMessage = "";
    switch (field) {
      case "cardNumber":
        errorMessage =
          value.length < 16 ? "Your card number is incomplete." : "";
        break;
      case "expirationDate":
        errorMessage = value.length < 5 ? "Enter a valid expiration date." : "";
        break;
      case "securityCode":
        errorMessage = value.length < 3 ? "Enter a valid security code." : "";
        break;
      case "postalCode":
        errorMessage = value === "" ? "Enter a valid postal code." : "";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: errorMessage }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let valid = true;
    Object.keys(formData).forEach((field) => {
      if (formData[field as keyof typeof formData] === "") {
        validateField(field, formData[field as keyof typeof formData]);
        valid = false;
      }
    });
    if (valid) {
      alert("Payment processed successfully!");
    }
  };

  return (
    <div className="payment-details">
      <h2>Payment Details</h2>
      <div className="tabs">
        <button
          type="button"
          className={activePaymentMethod === "card" ? "active-tab" : ""}
          onClick={() => setActivePaymentMethod("card")}
        >
          Card
        </button>
        <button
          type="button"
          className={activePaymentMethod === "googlePay" ? "active-tab" : ""}
          onClick={() => setActivePaymentMethod("googlePay")}
        >
          Google Pay
        </button>
      </div>

      <div
        className={`form-content ${
          activePaymentMethod === "card" ? "show" : ""
        }`}
      >
        {activePaymentMethod === "card" && (
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label>Card number</label>
              <input
                type="text"
                name="cardNumber"
                value={formData.cardNumber}
                onChange={handleChange}
                maxLength={16}
                placeholder="1234 1234 1234 1234"
                className={errors.cardNumber ? "error" : ""}
              />
              {errors.cardNumber && (
                <span className="error-message">{errors.cardNumber}</span>
              )}
            </div>
            <div className="input-row">
              <div className="input-group">
                <label>Expiration date</label>
                <input
                  type="text"
                  name="expirationDate"
                  value={formData.expirationDate}
                  onChange={handleChange}
                  maxLength={5}
                  placeholder="MM / YY"
                  className={errors.expirationDate ? "error" : ""}
                />
                {errors.expirationDate && (
                  <span className="error-message">{errors.expirationDate}</span>
                )}
              </div>
              <div className="input-group">
                <label>Security code</label>
                <input
                  type="text"
                  name="securityCode"
                  value={formData.securityCode}
                  onChange={handleChange}
                  maxLength={3}
                  placeholder="CVC"
                  className={errors.securityCode ? "error" : ""}
                />
                {errors.securityCode && (
                  <span className="error-message">{errors.securityCode}</span>
                )}
              </div>
            </div>

            <div className="input-row">
              <div className="input-group">
                <label>Country</label>
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option className="option" value="United Kingdom">
                    United Kingdom
                  </option>
                  <option value="United States">United States</option>
                </select>
              </div>
              <div className="input-group">
                <label>Postal code</label>
                <input
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                  placeholder="WS11 1DB"
                  className={errors.postalCode ? "error" : ""}
                />
                {errors.postalCode && (
                  <span className="error-message">{errors.postalCode}</span>
                )}
              </div>
            </div>
            <button type="submit" className="payment-btn">
              Make Payment £1500
            </button>
          </form>
        )}

        <div className="termsTxt">
          By proceeding, you agree to our{" "}
          <a href="https://lokem.co.uk/termsAndConditions">
            Terms and conditions
          </a>{" "}
          and <a href="https://lokem.co.uk/privacyPolicy">privacy policy</a>
        </div>
      </div>

      <div
        className={`form-content ${
          activePaymentMethod === "googlePay" ? "show" : ""
        }`}
      >
        {activePaymentMethod === "googlePay" && (
          <div className="google-pay-info">
            <p className="google-play-txt">
              <strong>Google Pay selected.</strong>
            </p>
            <p className="google-play-txt2">
              Another step will appear to securely submit your payment
              information.
            </p>
          </div>
        )}
        <button type="submit" className="payment-btn">
          Make Payment £1500
        </button>

        <div className="termsTxt">
          By proceeding, you agree to our{" "}
          <a href="https://lokem.co.uk/termsAndConditions">
            Terms and conditions
          </a>{" "}
          and <a href="https://lokem.co.uk/privacyPolicy">privacy policy</a>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
