import React from "react";
import { useState } from "react";
import "../../assets/scss/Admin/adminLogin.scss";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ username: "", password: "" });

  const validateForm = () => {
    let valid = true;
    const newErrors = { username: "", password: "" };

    if (!username.trim()) {
      newErrors.username = "Username is required";
      valid = false;
    }

    if (!password.trim()) {
      newErrors.password = "Password is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      // Proceed with login logic if form is valid
      console.log("Form Submitted");
      console.log("Username:", username);
      console.log("Password:", password);
    }
  };

  return (
    <div className="Login-page">
      <div className="login-container">
        <h2>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onBlur={() => {
                if (!username.trim())
                  setErrors((prev) => ({
                    ...prev,
                    username: "Username is required",
                  }));
                else setErrors((prev) => ({ ...prev, username: "" }));
              }}
            />
            {errors.username && (
              <p className="error-message">{errors.username}</p>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={() => {
                if (!password.trim())
                  setErrors((prev) => ({
                    ...prev,
                    password: "Password is required",
                  }));
                else setErrors((prev) => ({ ...prev, password: "" }));
              }}
            />
            {errors.password && (
              <p className="error-message">{errors.password}</p>
            )}
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
