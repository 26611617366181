import React, { useState } from "react";
import "../assets/scss/faq.scss";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Scrum?",
      answer:
        "Scrum is a framework for managing and completing complex projects.",
    },
    {
      question: "What is the difference between Scrum and Agile Development?",
      answer:
        "Agile Development is a software methodology, whereas Scrum is one of process frameworks that follows Agile..",
    },
    {
      question:
        "Do I need to have a background in IT to be able to undergo the course?",
      answer:
        "No. Anyone can take the course. Some of our past candidates are from teaching backgrounds; some are from retail, HR, sales etc. All you need is a desire to learn and the dedication to see it through.",
    },
    {
      question:
        "Can Product Owner and Scrum Master’s roles be played by the same person?",
      answer:
        "No, since the ownership differs. Product Owner takes care of the Product Backlog, Prioritization of User Stories, and Validation of the working product increment with the user stories allocated to the Sprint.",
    },
    {
      question: "What is a burn down chart?",
      answer:
        "A Burn Down Chart is an instrument to track the progress of the team during a Sprint. It shows on a daily basis how much story points have been done. The chart shows if it is likely that the team is going to meet the Sprint Goal.",
    },
    {
      question: "What is the difference with Kanban?",
      answer:
        "Kanban may look a lot like Scrum, but this is mostly because many teams use Scrum elements in their Kanban approach. Kanban is a LEAN technique that optimizes the flow of a process.",
    },
  ];

  return (
    <div className="faq">
      <h1>Frequently Asked Questions</h1>
      <h2>Discover answers related to scrum and how it can help you.</h2>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <div
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
            key={index}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span className="faq-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
              <h3>{faq.question}</h3>
            </div>
            <div className={`faq-answer`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
