import React, { Suspense, lazy } from "react";
import "./assets/css/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DataComponent from "./pages/DataComponent";
import CheckoutMain from "./pages/CheckoutMain";
import Home from "./pages/Home";
import Faq from "./pages/Faq";
import ContactUs from "./components/ContactUs";
import AboutUsPage from "./pages/AboutUsPage";
import Login from "./pages/Login";
import FaqPage from "./pages/FaqPage";
import HelpFaq from "./pages/HelpFaq";
import Testimonials from "./pages/Testimonials";
import CorporateTraining from "./pages/CorporateTraining";
import IndividualTraining from "./pages/IndividualTraining";
import ViewProgrammes from "./pages/ViewProgrammes";
import Blogs from "./pages/Blog/Blogs";
import SimiJourney from "./pages/Blog/SimiJourney";
import ScrumBenefit from "./pages/Blog/ScrumBenefit";
import EffectiveLeader from "./pages/Blog/EffectiveLeader";
import Procrastination from "./pages/Blog/Procrastination";
import UserStory from "./pages/Blog/UserStory";
import ImplementScrum from "./pages/Blog/ImplementScrum";
import AgileCulture from "./pages/Blog/AgileCulture";
import TopFive from "./pages/Blog/TopFiveAgile";
import AceYour from "./pages/Blog/AceYour";
import SprintReview from "./pages/Blog/SprintReview";
import CoronaVirus from "./pages/Blog/Coronavirus";
import ScrumMaster from "./pages/ScrumMaster";
import BusinessAnalyst from "./pages/BusinessAnalyst";
import ProductOwner from "./pages/ProductOwner";
import CourseConfirmation from "./pages/CourseConfirmation";
import HomePage from "./pages/HomePage";
import ProjectManagerTraining from "./pages/ProjectManagerTraining";
import MyComponent from "./pages/MyComponent";
import Cookies from "./pages/Cookies";
import PaymentDetails from "./pages/PaymentDetails";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import PersonalDetails from "./pages/PersonalDetails";
import ScrumMasterPD from "./pages/ScrumMasterPD";
import BusinessAnalystPD from "./pages/BusinessAnalystPD";
import ProductOwnerPD from "./pages/ProductOwnerPD";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./pages/Footer";
import Header from "./pages/Header";
import "font-awesome/css/font-awesome.min.css";
import AdminLogin from "./pages/Admin/AdminLogin";
import Welcome from "./pages/Admin/Welcome";
import Trainees from "./pages/Admin/Trainees";
import TrainingDates from "./pages/Admin/TrainingDates";

//const Hello = lazy(() => import("./DataComponent"));
const Loading = () => <div>Loading...</div>;

const App = () => (
  <div className="App">
    <header className="App-header">
      <Suspense fallback={<Loading />}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/" element={<DataComponent />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/aboutUs" element={<AboutUsPage />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/corporateTraining" element={<CorporateTraining />} />
            <Route
              path="/individualTraining"
              element={<IndividualTraining />}
            />
            <Route path="/viewProgrammes" element={<ViewProgrammes />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/simijourney" element={<SimiJourney />} />
            <Route path="/scrumbenefit" element={<ScrumBenefit />} />
            <Route path="/effectiveleader" element={<EffectiveLeader />} />
            <Route path="/procrastination" element={<Procrastination />} />
            <Route path="/userstory" element={<UserStory />} />
            <Route path="/implementscrum" element={<ImplementScrum />} />
            <Route path="/agileculture" element={<AgileCulture />} />
            <Route path="/topfiveagile" element={<TopFive />} />
            <Route path="/aceyourinterview" element={<AceYour />} />
            <Route path="/sprintreview" element={<SprintReview />} />
            <Route path="/coronaviruspandemic" element={<CoronaVirus />} />
            <Route path="/scrumMaster" element={<ScrumMaster />} />
            <Route path="/businessAnalyst" element={<BusinessAnalyst />} />
            <Route path="/productOwner" element={<ProductOwner />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/FaqPage" element={<Faq />} />
            <Route path="/HelpFaq" element={<HelpFaq />} />
            <Route path="/DataComponent" element={<DataComponent />} />
            <Route path="/checkout" element={<CheckoutMain />} />
            <Route path="/myComponent" element={<MyComponent />} />
            <Route
              path="/CourseConfirmation"
              element={<CourseConfirmation />}
            />
            <Route
              path="/projectManager"
              element={<ProjectManagerTraining />}
            />
            <Route path="/HomePage" element={<HomePage />} />
            <Route path="/Payment" element={<PaymentDetails />} />
            <Route
              path="/paymentconfirmation"
              element={<PaymentConfirmation />}
            />
            <Route path="/personaldetails" element={<PersonalDetails />} />
            <Route path="/productOwnerPD" element={<ProductOwnerPD />} />
            <Route path="/scrumMasterPD" element={<ScrumMasterPD />} />
            <Route path="/businessAnalystPD" element={<BusinessAnalystPD />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/adminLogin" element={<AdminLogin />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/trainees" element={<Trainees />} />
            <Route path="/trainingdates" element={<TrainingDates />} />
          </Routes>
          <Cookies />
          <Footer />
        </Router>
      </Suspense>
    </header>
  </div>
);

export default App;
